import { Injectable } from '@angular/core';
import { RouterModule, Routes, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Http, Response, RequestOptions, Headers, URLSearchParams } from '@angular/http';
import { catchError } from 'rxjs/operators';

import { APIClientService } from '../service/APIClientService';
import { CommonService } from '../service/common.service';
import * as URL from '../constants/url';
import { CONSTANTS } from '../constants/constant';
import * as ROUTS from '../constants/routs';


declare var $: any;

@Injectable()
export class ReviewmediaService {

	constructor(public router: Router, private httpClient: HttpClient, public apiClientService: APIClientService, public commonService: CommonService) { }

	/**
	 * Gets the list of all trainers
	 */
	getVerifiedUserList(role: any, territoryId): Observable<any> {

		return this.apiClientService.doGet(URL.GET_ALL_VERIFIED_USERS + '/' + role + '/' + territoryId);
	}

	/**
	 * Gets the list of all trainers by territoryId
	 */
	 getTrainerListByTerritoryId(territoryId: any): Observable<any> {

		return this.apiClientService.doGet(URL.GET_ALL_TRAINERS_BY_TERRITORY_ID + '/' + territoryId);
	}

	/**
	 * Gets the list of all trainers by territoryId
	 */
	 getDogListByTerritoryId(territoryId: any): Observable<any> {

		return this.apiClientService.doGet(URL.GET_ALL_DOGS_BY_TERRITORY_ID + '/' + territoryId);
	}

	/**
	 * Gets the list of all trainers
	 */
	getDogList(trainerId, territoryId): Observable<any> {

		if ('' == trainerId || null == trainerId)
			return this.apiClientService.doGet(URL.GET_ALL_DOG_LIST + '/' + territoryId);
		else
			return this.apiClientService.doGet(URL.GET_DOG_LIST + '/' + trainerId);
	}

	/**
	 * Gets the list of all trainers
	 */
	getDogListForExpenses(trainerId, showAllDogFlag): Observable<any> {

		if ('' == trainerId || null == trainerId)
			return this.apiClientService.doGet(URL.GET_ALL_DOG_LIST);
		else
			return this.apiClientService.doGet(URL.GET_DOG_LIST + '/' + trainerId + '/' + showAllDogFlag);
	}

	/**
	 * Gets the list of all trainers
	 */
	getAllMedia(mediaStatus, trainerId, dogId, date, territoryId): Observable<any> {

		let urlSearchParams = new URLSearchParams();

		urlSearchParams.set('mediaStatus', mediaStatus);
		urlSearchParams.set('trainerId', trainerId);
		urlSearchParams.set('dogId', dogId);
		urlSearchParams.set('date', date);
		urlSearchParams.set('territoryId', territoryId);

		return this.apiClientService.doPostWithHttp(URL.GET_REVIEW_MEDIA_BY_FILTER, urlSearchParams);

	}

	/**
	 * Approve media
	 */
	approveMediaStatus(mediaStatusForm: any): Observable<any> {
		return this.apiClientService.doPost(URL.APPROVE_MEDIA, mediaStatusForm);
	}

	/**
	 * Reject media
	 */
	rejectMediaStatus(mediaStatusForm: any): Observable<any> {
		return this.apiClientService.doPost(URL.REJECT_MEDIA, mediaStatusForm);
	}

	/**
 * Gets the list of all trainers
 */
	getAllDates(mediaStatus, trainerId, dogId,territoryId): Observable<any> {

		let urlSearchParams = new URLSearchParams();

		urlSearchParams.set('mediaStatus', mediaStatus);
		urlSearchParams.set('trainerId', trainerId);
		urlSearchParams.set('dogId', dogId);
		urlSearchParams.set('territoryId', territoryId);

		return this.apiClientService.doPostWithHttp(URL.GET_DATE_LIST, urlSearchParams);

	}

	/**
	 * Approve Note
	 */
	approveNotes(noteForm: any): Observable<any> {
		return this.apiClientService.doPost(URL.APPROVE_NOTE, noteForm);
	}

	/**
	 * Reject Note
	 */
	rejectNotes(noteForm: any): Observable<any> {
		return this.apiClientService.doPost(URL.REJECT_NOTE, noteForm);
	}

	/**
	 * Approve All Note And Media
	 */
	approveAllNotesAndMedia(noteForm: any): Observable<any> {
		return this.apiClientService.doPost(URL.APPROVE_ALL_NOTE_MEDIA, noteForm);
	}

	/**
	 * Reject All Note And Media
	 */
	rejectAllNotesAndMedia(noteForm: any): Observable<any> {
		return this.apiClientService.doPost(URL.REJECT_ALL_NOTE_MEDIA, noteForm);
	}


	/**
	 * Delete All Note And Media
	 */
	deleteAllNotesAndMedia(noteForm: any): Observable<any> {
		return this.apiClientService.doPost(URL.DELETE_ALL_NOTE_MEDIA, noteForm);
	}

	getActiveTrainerList(territoryId): Observable<any> {

		return this.apiClientService.doGet(URL.GET_ALL_ACTIVE_TRAINERS_BY_TERRITORY + '/' + territoryId);
	}

	getActiveTrainerListExceptCurrentTrainer(dogProgramId): Observable<any> {

		return this.apiClientService.doGet(URL.GET_ALL_ACTIVE_TRAINERS + '/' + dogProgramId);
	}

}