import { environment } from '../../environments/environment';

'use strict';

export const BASE_URL: string = environment.apiUrl;
export const VIDEO_STREAM_URL: string = environment.videoUrl;

// Outh
export const LOGIN: string = BASE_URL + "oauth/token";
export const AUTHORIZATION: string = "Basic UEstY2xpZW50OlBLLXNlY3JldA==";
export const BEARER: string = "Bearer ";
export const LOGOUT: string = BASE_URL + "oauth/logout";

//Get list of owner dogs
export const GET_ALL_OWNER_DOGS: string = BASE_URL + "api/dog/getAllOwnerDogs";
export const GET_ALL_TRAINER_DOGS: string = BASE_URL + "api/dog/getAllTrainerDogs";

//Get dog profile data
export const GET_DOG_PROFILE_BY_ID: string = BASE_URL + "api/dogprogram/get";
export const GET_DIARY_LIST: string = BASE_URL + "api/dogprogram/get/list";
export const GET_ENROLLMENT_FOR_CALENDAR_VIEW: string = BASE_URL + "api/dogprogram/getEnrollmentForCalendarView"

//Get dog diary data
export const GET_DIARY: string = BASE_URL + "api/dogdiary/get/diary";
export const GET_DIARY_FROM_DATE: string = BASE_URL + "api/dogprogram/get";
export const GET_MEDIA_FROM_DATE: string = BASE_URL + "api/dogmedia/get";
export const RENDER_MEDIA: string = BASE_URL + "api/dogmedia/render";
export const ADD_NOTE: string = BASE_URL + "api/dogdiary/save/note";
export const UPLOAD_FILE: string = BASE_URL + "api/dogmedia/save";
export const DELETE_DOG_DIARY: string = BASE_URL + "api/dogprogram/delete";
export const DELETE_MEDIA: string = BASE_URL + "api/dogmedia/delete";
export const IS_VALID_DATE: string = BASE_URL + "api/dogdiary/isValidDate";
export const UPDATE_MEDIA_CAPTION: string = BASE_URL + "api/dogmedia/updateMediaCaption";
export const UPDATE_IMAGE_ROTATION: string = BASE_URL + "api/dogmedia/updateImageRotation";
export const GET_VIDEO_COUNT: string = BASE_URL + "noAuth/get/videoCount";
export const DELETE_NOT_UPLOADED_MEDIA: string = BASE_URL + "api/dogmedia/deleteNotUploaded";


//Get reviewMedia data
export const GET_ALL_VERIFIED_USERS: string = BASE_URL + "api/user/get/active";
export const GET_DOG_LIST: string = BASE_URL + "api/dog/bytrainer";
export const GET_ALL_DOG_LIST: string = BASE_URL + "api/dog/getAllDogs";
export const GET_REVIEW_MEDIA_BY_FILTER: string = BASE_URL + "api/dogmedia/getAllByFilter";
export const APPROVE_MEDIA: string = BASE_URL + "api/dogmedia/approve";
export const REJECT_MEDIA: string = BASE_URL + "api/dogmedia/reject";
export const GET_DATE_LIST: string = BASE_URL + "api/dogmedia/getDateList";
export const APPROVE_NOTE: string = BASE_URL + "api/dogdiary/approve";
export const REJECT_NOTE: string = BASE_URL + "api/dogdiary/reject";
export const GET_ALL_DOG_BY_OWNER: string = BASE_URL + "api/dog/getAllbyOwnerId";
export const APPROVE_ALL_NOTE_MEDIA: string = BASE_URL + "api/dogdiary/approveAll";
export const REJECT_ALL_NOTE_MEDIA: string = BASE_URL + "api/dogdiary/rejectAll";
export const DELETE_ALL_NOTE_MEDIA: string = BASE_URL + "api/dogdiary/deleteAll";
export const GET_ALL_TRAINERS_BY_TERRITORY_ID: string = BASE_URL + "api/user/getTrainersByTerritoryId";
export const GET_ALL_DOGS_BY_TERRITORY_ID: string = BASE_URL + "api/dog/getAllDogsByTerritoryId";

//Trainer API
export const DELETE_TRAINER: string = BASE_URL + "api/user/delete";
export const ADD_TRAINER: string = BASE_URL + "api/user/save";
export const GET_TRAINER_DATA: string = BASE_URL + "api/user/getUserById";
export const RESTORE_TRAINER: string = BASE_URL + "api/user/restore";
export const RESTORE_DOG: string = BASE_URL + "api/user/restoreDog";
export const GET_PAGE_WISE_TRAINER: string = BASE_URL + "api/user/getPageWiseTrainerList/active";
export const GET_PAGE_WISE_DOG: string = BASE_URL + "api/dog/getPageWiseDogList/active";
export const GET_CURRENT_USER: string = BASE_URL + "api/user/getCurrentUser";
export const GET_COUNT_TRAINER_ACTIVE_PROGRAM: string = BASE_URL + "api/user/getCountTrainerActiveProgram";
export const GET_ALL_ACTIVE_TRAINERS: string = BASE_URL + "api/user/get/activeTrainer";
export const GET_ALL_ACTIVE_TRAINERS_BY_TERRITORY: string = BASE_URL + "api/user/get/activeTrainer/by/territory";
export const GET_ALL_VERIFIED_TRAINER: string = BASE_URL + "api/user/get/verifiedTrainers";

export const GET_ALL_EXPENSES_TYPES_TRAINER: string = BASE_URL + "api/expenses/getAllExpensesType";
export const SAVE_EXPENSE_TYPE: string = BASE_URL + "api/expenses/saveExpensesType";

export const SAVE_EXPENSES: string = BASE_URL + "api/expenses/save";

export const GET_ALL_PROGRAM_DATA: string = BASE_URL + "api/program/get/all";
export const GET_PROGRAM_DATA: string = BASE_URL + "api/program/get/all/active";

export const DELETE_PROGRAM: string = BASE_URL + "api/program/delete";
export const ADD_PROGRAM: string = BASE_URL + "api/program/save";
export const GET_PROGRAM_BY_ID: string = BASE_URL + "api/program/getProgramDataById";
export const RESTORE_PROGRAM: string = BASE_URL + "api/program/restore";
export const GET_PAGE_WISE_PROGRAM: string = BASE_URL + "api/program/get/all/activePageWise";

export const RESEND_PASSWORD_LINK: string = BASE_URL + "api/user/resendPasswordLink";
export const CHANGE_ADMIN_PASSWORD: string = BASE_URL + "api/user/changePassword";
export const CHANGE_USER_PASSWORD: string = BASE_URL + "api/dog/changePassword";

export const VERIFY_USER: string = BASE_URL + "noAuth/verifyUser";
export const SET_PASSWORD: string = BASE_URL + "noAuth/setPassword";
export const FORGOT_PASSWORD: string = BASE_URL + "noAuth/forgotPassword";
export const CHECK_IS_USER_VERIFIYED: string = BASE_URL + "noAuth/isUserVerified";

//Veterinarian API
export const GET_ALL_VETERINARIAN: string = BASE_URL + "api/veterinarian/getAllVeterinarian";
export const ADD_VETERINARIAN: string = BASE_URL + "api/veterinarian/save";

//Dog Trainer
export const GET_PAGE_WISE_DOG_TRAINER: string = BASE_URL + "api/dogtrainer/getPageWiseEnrollmentsList/active";
export const ADD_ENROLLMENT: string = BASE_URL + "api/dogtrainer/saveEnrollments"
export const DELETE_DOG_TRAINER: string = BASE_URL + "api/dogtrainer/delete"
export const GET_ENROLLMENT: string = BASE_URL + "api/dogtrainer/getEnrollment"
export const GET_DOG_TRAINER_BY_PROGRAM: string = BASE_URL + "api/dogtrainer/getDogTrainerByProgram"
export const DELETE_ENROLLMENT: string = BASE_URL + "api/dogtrainer/deleteEnrollments"
export const ADD_DOG_TRAINER: string = BASE_URL + "api/dogtrainer/saveDogTrainer"
export const UPDATE_TOTAL_DAYS_DOG_TRAINER: string = BASE_URL + "api/dogtrainer/updateDogTrainerTotalDays"
export const DOWNLOAD_DOG_REPORT: string = BASE_URL + "api/dogtrainer/download/pdf"
export const DOWNLOAD_DOG_DIARY_REPORT: string = BASE_URL + "api/dogtrainer/downloadDogDiary/pdf"
export const GET_DELIVERY_STATUS: string = BASE_URL + "api/dogtrainer/get/deliveryStatusList"
export const GET_PAYROLL_DATA: string = BASE_URL + "api/dogtrainer/getPayrollData"
export const DOWNLOAD_PAYROLL_PDF: string = BASE_URL + "api/dogtrainer/downloadPayroll/pdf"
export const GET_PAYROLL_EXPENSES_DATA: string = BASE_URL + "api/expenses/getPayrollExpensesData"



//Owner 
export const GET_ALL_OWNER: string = BASE_URL + "api/user/getAllOwner";

//Upload User Profile Picture
export const UPLOAD_PROFILE_FILE: string = BASE_URL + "api/user/saveProfilePicture";
export const UPDATE_PROFILE_DETAIL: string = BASE_URL + "api/user/updateProfileDetail";

//payment 
export const GET_PAYMENT_HISTORY: string = BASE_URL + "api/payment/getTransactionHistory"
export const GET_STRIPE_USER: string = BASE_URL + "api/payment/getStripeUser"
export const GET_STRIPE_USER_IS_EDIT: string = BASE_URL + "api/payment/getStripeUserForIsEdit"
//get enpenses data page wise
export const GET_PAGE_WISE_EXPENSES: string = BASE_URL + "api/expenses/get/all/activePageWise";
export const DELETE_EXPENSES: string = BASE_URL + "api/expenses/delete";
export const GET_EXPENSES: string = BASE_URL + "api/expenses/getExpenseById";
export const IS_EXPENSE_EXIST: string = BASE_URL + "api/expenses/isExpenseTypeExist";
export const IS_EXPENSES_AVAILABLE: string = BASE_URL + "api/expenses/isExpenseAvailable";
export const DELETE_ALL_EXPENSES: string = BASE_URL + "api/expenses/deleteAllExpenses";

export const VIDEO_RENDER_API: string = VIDEO_STREAM_URL + "noAuth/render";
export const NO_AUTH_LOGIN: string = BASE_URL + "noAuth/loginAsOwner";

export const CHECK_EMAIL_IS_ALREADY_EXIST: string = BASE_URL + "api/user/checkEmailIsAlreadyExist";
export const CHECK_FRANCHISE_NAME_IS_ALREADY_EXIST: string = BASE_URL + "api/franchise/checkNameIsAlreadyExist";
export const CHECK_TERRITORY_NAME_IS_ALREADY_EXIST: string = BASE_URL + "api/territory/checkNameIsAlreadyExist";
export const CHECK_ZIPCODE_IS_ALREADY_EXIST: string = BASE_URL + "api/territory/isZipcodeExist";
export const CHECK_ZIPCODE_IS_ALREADY_EXIST_FOR_RESTORE: string = BASE_URL + "api/territory/isZipcodeExist/forRestore";
export const CHECK_SYSTEM_SETTING: string = BASE_URL + "noAuth/getSystemSetting";
export const UPDATE_DOG_DIARY_FEATURE = BASE_URL + "noAuth/saveSystemSetting";

//messages api

export const GET_ALL_MESSAGES_OF_OWNER_OR_TRAINER = BASE_URL + "api/messages/get/messagesOfOwnerOrTrainer";
export const SEND_MESSAGES = BASE_URL + "api/messages/save";
export const SEEN_ALL_MESSAGES = BASE_URL + "api/messages/seen";
export const GET_ALL_MESSAGES_OF_ADMIN = BASE_URL + "api/messages/getAll/message";
export const SEEN_MESSAGES = BASE_URL + "api/messages/getById";
export const APPROVE_MESSAGES = BASE_URL + "api/messages/approve";
export const APPROVE_ALL_MESSAGES = BASE_URL + "api/messages/approveAll";
export const REJECT_MESSAGES = BASE_URL + "api/messages/reject";
export const REJECT_ALL_MESSAGES = BASE_URL + "api/messages/rejectAll";
export const DIS_APPROVE_MESSAGES = BASE_URL + "api/messages/dis-approve";
export const GET_ALL_MESSAGES_BY_FILTER = BASE_URL + "api/messages/getAllMessagesByFilter";
export const UPDATE_MESSAGES = BASE_URL + "api/messages/updateMessage";
export const GET_ALL_OWNER_DOGS_BY_FILTER: string = BASE_URL + "api/messages/getAllOwnerDogsByNameFilter";
export const GET_ALL_TRAINER_DOGS_FOR_MESSAGES: string = BASE_URL + "api/messages/getAllTrainerDogs";
export const UPDATE_MESSAGES_AND_MESSAGE_STATUS = BASE_URL + "api/messages/updateMessageAndMessageStatus";
export const DELETE_ALL_MESSAGES = BASE_URL + "api/messages/deleteAll";


//user api

export const GET_LOGED_USER = BASE_URL + "api/user/get/loggedInUser";
export const GET_USER_BY_ID = BASE_URL + "api/user/getUserById";


//support api
export const GET_ALL_TICKET = BASE_URL + "api/support/getAllTicket";
export const GET_ALL_TICKET_FOR_OWNER_OR_TRAINER = BASE_URL + "api/support/getAllTicketForOwnerOrTrainer";

export const SAVE_TICKET: string = BASE_URL + "api/support/saveTicket";
export const UPDATE_TICKET_STATUS = BASE_URL + "api/support/updateStatus";
export const GET_TICKET_BY_ID = BASE_URL + "api/support/getCommentsByTicketId";
export const SEND_COMMENTS_AND_ATTACHMENT = BASE_URL + "api/support/saveCommentAndAttachment";
export const GET_UNREAD_TICKET_COUNT = BASE_URL + "api/support/getUnreadTicketCount"
export const SEEN_FALG = BASE_URL + "api/support/seenFlag";


//franchise api
export const GET_PAGE_WISE_FRANCHISE: string = BASE_URL + "api/franchise/getPageWiseFranchiseList";
export const SAVE_FRANCHISE: string = BASE_URL + "api/franchise/save";
export const GET_FRANCHISE_BY_ID: string = BASE_URL + "api/franchise/getFranchiseById";
export const GET_ALL_ACTIVE_FRANCHISE = BASE_URL + "api/franchise/getAllActiveFranchise";
export const CHECK_EMAIL_IS_ALREADY_EXIST_FOR_FRANCHISE: string = BASE_URL + "api/franchise/checkEmailIsAlreadyExist";



//territory api

export const GET_PAGE_WISE_TERRITORY = BASE_URL + "api/territory/getPageWiseTerritoryList";
export const ADD_TERRITORY = BASE_URL + "api/territory/save";
export const DELETE_TERRITORY = BASE_URL + "api/territory/deleteTerritory";
export const GET_TERRITORY_BY_ID = BASE_URL + "api/territory/getTerritoryById";
export const GET_TERRITORY_TO_ADD_USER = BASE_URL + "api/territory/getTerritoriesToAddUser";
export const GET_ALL_ACTIVE_TERRITORY = BASE_URL + "api/territory/getAllActiveTerritory";
export const GET_ALL_ACTIVE_TERRITORY_WITHOUT_FRANCHISE = BASE_URL + "api/territory/getAllActiveTerritoryWithoutFranchise";
export const GET_ALL_ACTIVE_TERRITORY_WITH_FRANCHISE = BASE_URL + "api/territory/getAllActiveTerritoryWithFranchise";
export const GET_TERRITORY_LIST_FOR_FRANCHISE = BASE_URL + "api/territory/getTerritoryListForFranchiseOwner";
export const GET_TERRITORY_LIST_FOR_MANAGER = BASE_URL + "api/territory/getTerritoryListForTerritoryManager";
export const GET_TERRITOTY_LIST_WITH_NO_TERRITORYMANAGER = BASE_URL + "api/territory/findTerritoryWithNoTerritoryManagerByFranchiseId";
export const FIND_All_UN_ASSIGNED_TERRITORY_MANAGER_TO_TERRITORY = BASE_URL + "api/user/findAllUnAssignedTerritoryManagerToTerritory";

export const FIND_ALL_TERRITORY_MANAGER_BY_FRANCHISE = BASE_URL + "api/user/findAllTerritoryManagerByFranchise"

export const GET_TERRATORY_STATE_CITY = BASE_URL + "api/usps/cityStateLookup";
//role api

export const GET_ALL_ROLES = BASE_URL + "api/role/getAllRoles";
export const GET_ROLE_BY_ID = BASE_URL + "api/role/getById";
export const GET_SAVE_ROLE = BASE_URL + "api/role/saveRole";
export const ROLE_NAME_EXIST = BASE_URL + "api/role/checkRoleNameExist";
export const DELETE_ROLE_BY_ID = BASE_URL + "api/role/deleteRoleById";
export const GET_ALL_ROLES_BY_IS_ACTIVE_IS_DELETE = BASE_URL + "api/role/getAllRoleByIsDelete";
export const GET_ALL_DELETED_ROLES = BASE_URL + "api/role/getAllDeletedRole";
export const RESTORE_ROLE_BY_ID = BASE_URL + "api/role/restoreRoleById";

//permission api
export const GET_PERMISSION_BY_MODULE = BASE_URL + "api/permission/getAllPermissionByModule";

//manage user api

export const GET_USER_LIST_PAGE_WISE = BASE_URL + "api/user/getPageWiseFranchiseList";
export const DELETE_USER = BASE_URL + "api/user/deleteUserData";
export const DELETE_FRANCHIS = BASE_URL + "api/franchise/deleteFranchise";
export const SAVE_USER = BASE_URL + "api/user/save"
export const GET_USER_FOR_FRANCHISEOWNER_BY_ID = BASE_URL + "api/user/getUserForFranchiseOwnerById"


//enrollment 
export const GET_ALL_OWNER_BY_TERRITORY: string = BASE_URL + "api/user/getAllOwnerByTerritoryId";
export const GET_ALL_PROGRAM_BY_FRANCHISE: string = BASE_URL + "api/program/get/all/activeByFranchiseId";