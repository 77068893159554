import { Injectable } from '@angular/core';
import { Cookie } from 'ng2-cookies';
import { RouterModule, Routes, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { CONSTANTS } from '../constants/constant';
import Swal from 'sweetalert2';

import * as ROUTS from '../constants/routs';
import * as CryptoJS from 'crypto-js';

import * as moment from 'moment';
import 'moment-timezone';
import { Http, Response } from '@angular/http';

import { DatePipe } from '@angular/common';
import { ROLE } from '../constants/role';

import { ReactiveFormsModule, FormsModule, FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
declare var $: any;

@Injectable()
export class CommonService {

	constructor(private http: Http, private router: Router, private datePipe: DatePipe) { }

	public isBack: boolean = false;

	public isLogin: boolean = false;

	public isSupportTag: boolean = false;

	public dogProgramId: any;

	public dogName: string;

	public permission: any = "";

	amountPattern: RegExp = /^\d+(\.\d{1,2})?$/;
	numericPattern: RegExp = /^[1-9]\d*$/;

	/**
	 * Set cookie
	 */
	setCookie(key: string, value: any, cookieExpirationDays: number) {

		let path: string = "/";

		//If cookie already exists then remove it
		if (this.getCookie(key)) {
			this.removeCookie(key);
		}

		if (cookieExpirationDays) {
			Cookie.set(key, value, (cookieExpirationDays / 60 / 60 / 24), path);
			//Cookie.set(key, value, cookieExpirationDays);
		}
		else {
			Cookie.set(key, value, undefined, path);
		}
	}


	/**
	 * Method to get cookie
	 * 
	 * @param {string}
	 *            key [description]
	 */
	getCookie(key: string): any {

		return Cookie.get(key);
	}

	checkCookie(key: string): boolean {

		return Cookie.check(key);
	}

	/**
	 * Method to check user login by cookie
	 */
	checkLogin(): boolean {
		// if (this.checkIsNullOrUndefined(this.getCookie(CONSTANTS.COOKIE_ACCESSTOKEN)))
		// 	return false;

		return true;
	}

	/**
	 * Method to redirect
	 */
	checkLoginAndRedirect(): boolean {
		console.log('check login and redirect')
		// if (!this.checkLogin())
		// 	return false;
		return true;
	}

	/**
	 * Encrypt
	 * 
	 * @param {string}
	 *            normalData [description]
	 * @return {string} [description]
	 */
	encrypt(normalData: string): string {

		if (!normalData)
			return null;

		return CryptoJS.AES.encrypt(normalData, CONSTANTS.ENCRYPT_DECRYPT_SECRET_KEY);
	}

	/**
	 * Decrypt
	 * 
	 * @param {string}
	 *            encryptedData [description]
	 * @return {string} [description]
	 */
	decrypt(encryptedData: string): string {

		if (!encryptedData)
			return null;

		var decrypted = CryptoJS.AES.decrypt(encryptedData, CONSTANTS.ENCRYPT_DECRYPT_SECRET_KEY);
		return decrypted ? decrypted.toString(CryptoJS.enc.Utf8) : null;
	}

	/**
	 * Method to delete cookie
	 * 
	 * @param {string}
	 *            key [description]
	 */
	deleteCookie() {

		Cookie.deleteAll('/');
	}

	/**
	 * Check is null
	 */
	checkIsNullOrUndefined(data: any) {
		if (data == null || data == undefined || data == '')
			return true;
		return false;
	}

	/**
	 * remove cookie
	 */
	removeCookie(key: string) {

		let path: string = "/";

		Cookie.delete(key, path);
	}

	/**
	 * logout time remove cookie data 
	 */
	removeData() {
		return new Promise((resolve) => {
			this.removeCookie(CONSTANTS.COOKIE_ACCESSTOKEN);
			this.removeCookie(CONSTANTS.COOKIE_REFRESHTOKEN);
			this.removeCookie(CONSTANTS.COOKIE_USER_ROLE);
			this.removeCookie(CONSTANTS.COOKIE_USER_PERMISSION);
			this.removeCookie(CONSTANTS.COOKIE_PERMISSION);
			this.removeCookie(CONSTANTS.COOKIE_USER_ROLE_LABEL);
			this.removeCookie(CONSTANTS.COOKIE_SUSPEND_WARNING);
			localStorage.removeItem(CONSTANTS.EMAIL);

			resolve("");
		});
	}

	/**
	 * logout time remove cookie data 
	 */
	setAuthorizationDataInCookie(results) {

		return new Promise((resolve) => {
			this.setCookie(CONSTANTS.COOKIE_USER_ROLE, results.role, CONSTANTS.LOGIN_COOKIE_EXPIRATION_DAYS);
			this.setCookie(CONSTANTS.COOKIE_USER_ROLE_LABEL, results.roleLabel, CONSTANTS.LOGIN_COOKIE_EXPIRATION_DAYS);

			let encryptedToken: string = this.encrypt(results.access_token);
			let refreshToken: string = this.encrypt(results.refresh_token);

			//Save the access token and path in Cookie
			this.setCookie(CONSTANTS.COOKIE_ACCESSTOKEN, encryptedToken, CONSTANTS.LOGIN_COOKIE_EXPIRATION_DAYS);
			this.setCookie(CONSTANTS.COOKIE_REFRESHTOKEN, refreshToken, CONSTANTS.LOGIN_COOKIE_EXPIRATION_DAYS);
			this.setCookie(CONSTANTS.COOKIE_USER_NAME, results.name, CONSTANTS.LOGIN_COOKIE_EXPIRATION_DAYS);

			this.setCookie(CONSTANTS.COOKIE_PERMISSION, results.permissions, CONSTANTS.LOGIN_COOKIE_EXPIRATION_DAYS);

			localStorage.setItem(CONSTANTS.EMAIL, results.email);
			resolve("");
		});
	}

	/**
	 * get language
	 */
	getLanguage() {

		if (!localStorage.hasOwnProperty(CONSTANTS.LANGUAGE))
			localStorage.setItem(CONSTANTS.LANGUAGE, CONSTANTS.LANGUAGE_DEFAULT);

		return localStorage.getItem(CONSTANTS.LANGUAGE);
	}

	redirectToLogin() {

		this.router.navigate([ROUTS.SIGN_IN]);
	}

	redirectUrl() {

		window.location.reload();

	}

	/**
	 * Get Local Browser Timezone id
	 */
	getLocalTimeZone() {

		if (!localStorage.hasOwnProperty(CONSTANTS.TIME_ZONE)) {

			moment.tz.setDefault(moment.tz.guess());
			localStorage.setItem(CONSTANTS.TIME_ZONE, moment().tz());
		}

		return localStorage.getItem(CONSTANTS.TIME_ZONE);

	}

	/**
	 * Redirect user to home page according to user role
	 * 
	 */
	redirectUserByUserRole() {

		this.permission = this.getPermissionList();
		var userRole = this.getCookie(CONSTANTS.COOKIE_USER_ROLE);

		return new Promise((resolve) => {
			if (this.permission.includes(ROLE.MANAGE_DOGS_CAN_MANAGE) || this.permission.includes(ROLE.MANAGE_DOGS_CAN_VIEW))
				this.router.navigateByUrl('/' + ROUTS.MY_DOGS);
			else if (this.permission.includes(ROLE.MANAGE_MEDIA_CAN_MANAGE) || this.permission.includes(ROLE.MANAGE_MEDIA_CAN_VIEW))
				this.router.navigateByUrl('/' + ROUTS.REVIEW_MEDIA);
			else if (this.permission.includes(ROLE.MANAGE_CALENDAR_CAN_VIEW))
				this.router.navigateByUrl('/' + ROUTS.CALENDAR_VIEW);
			else if (this.permission.includes(ROLE.MANAGE_OWNERS_CAN_MANAGE) || this.permission.includes(ROLE.MANAGE_OWNERS_CAN_VIEW))
				this.router.navigateByUrl('/' + ROUTS.MANAGE_OWNER);
			else if (this.permission.includes(ROLE.MANAGE_ENROLLMENTS_CAN_MANAGE) || this.permission.includes(ROLE.MANAGE_ENROLLMENTS_CAN_VIEW))
				this.router.navigateByUrl('/' + ROUTS.MANAGE_ENROLLMENTS);
			else if (this.permission.includes(ROLE.MANAGE_TRAINERS_CAN_MANAGE) || this.permission.includes(ROLE.MANAGE_TRAINERS_CAN_VIEW))
				this.router.navigateByUrl('/' + ROUTS.MANAGE_TRAINER);
			else if (this.permission.includes(ROLE.MANAGE_FRANCHISE_CAN_MANAGE) || this.permission.includes(ROLE.MANAGE_FRANCHISE_CAN_VIEW))
				this.router.navigateByUrl('/' + ROUTS.FRANCHISE);
			else if (this.permission.includes(ROLE.MANAGE_TERRITORY_CAN_MANAGE) || this.permission.includes(ROLE.MANAGE_TERRITORY_CAN_VIEW))
				this.router.navigateByUrl('/' + ROUTS.TERRITORY);
			else if (this.permission.includes(ROLE.MANAGE_ACCOUTANT_CAN_MANAGE) || this.permission.includes(ROLE.MANAGE_ACCOUTANT_CAN_VIEW))
				this.router.navigateByUrl('/' + ROUTS.MANAGE_ACCOUNTANT);
			else if (this.permission.includes(ROLE.MANAGE_USER_CAN_MANAGE) || this.permission.includes(ROLE.MANAGE_USER_CAN_VIEW))
				this.router.navigateByUrl('/' + ROUTS.USER);
			else if (this.permission.includes(ROLE.MANAGE_PROGRAMS_CAN_MANAGE) || this.permission.includes(ROLE.MANAGE_PROGRAMS_CAN_VIEW))
				this.router.navigateByUrl('/' + ROUTS.MANAGE_PROGRAM);
			else if (this.permission.includes(ROLE.MANAGE_MESSAGES_CAN_MANAGE) || this.permission.includes(ROLE.MANAGE_MESSAGES_CAN_VIEW)) {
				if (userRole == ROLE.TRAINER || userRole == ROLE.OWNER)
					this.router.navigateByUrl('/' + ROUTS.MESSAGES);
				else
					this.router.navigateByUrl('/' + ROUTS.MANAGE_MESSAGES);
			}
			else if (this.permission.includes(ROLE.MANAGE_PAYROLL_CAN_VIEW))
				this.router.navigateByUrl('/' + ROUTS.PAYROLL);
			else if (this.permission.includes(ROLE.MANAGE_EXPENSES_CAN_MANAGE) || this.permission.includes(ROLE.MANAGE_EXPENSES_CAN_VIEW))
				this.router.navigateByUrl('/' + ROUTS.MANAGE_EXPENSES);
			else if (this.permission.includes(ROLE.MANAGE_SETTINGS_CAN_MANAGE) || this.permission.includes(ROLE.MANAGE_SETTINGS_CAN_VIEW))
				this.router.navigateByUrl('/' + ROUTS.SYSTEM_SETTING);
			else if (this.permission.includes(ROLE.MANAGE_ROLE_CAN_MANAGE) || this.permission.includes(ROLE.MANAGE_ROLE_CAN_VIEW))
				this.router.navigateByUrl('/' + ROUTS.MANAGE_ROLE);
			else if (this.permission.includes(ROLE.MANAGE_SUPPORT_CAN_MANAGE) || this.permission.includes(ROLE.MANAGE_SUPPORT_CAN_VIEW))
				this.router.navigateByUrl('/' + ROUTS.SUPPORT);
			else if (this.permission.includes(ROLE.MANAGE_PERSONS_CAN_MANAGE) || this.permission.includes(ROLE.MANAGE_PERSONS_CAN_VIEW))
				this.router.navigateByUrl('/' + ROUTS.PERSONS);
			else if (this.permission.includes(ROLE.MANAGE_LEADS_CAN_MANAGE) || this.permission.includes(ROLE.MANAGE_LEADS_CAN_VIEW))
				this.router.navigateByUrl('/' + ROUTS.LEADS);
			else if (this.permission.includes(ROLE.MANAGE_DEALS_CAN_MANAGE) || this.permission.includes(ROLE.MANAGE_DEALS_CAN_VIEW))
				this.router.navigateByUrl('/' + ROUTS.DEALS);
			// else {
			// 	// this.layoutService.logOut().subscribe(results => {
			// 	// });
			// 	// this.removeData();
			// 	// this.showMessage("There is some problem, please try again later", 'error');
			// 	// this.router.navigateByUrl('/' + ROUTS.SIGN_IN);
			// }
			resolve("");
		});
	}

	redirectLoginAsOwner(): string {

		this.permission = this.getPermissionList();

		if (this.permission.includes(ROLE.MANAGE_DOGS_CAN_MANAGE) || this.permission.includes(ROLE.MANAGE_DOGS_CAN_VIEW))
			return ROUTS.MY_DOGS
		else if (this.permission.includes(ROLE.MANAGE_MEDIA_CAN_MANAGE) || this.permission.includes(ROLE.MANAGE_MEDIA_CAN_VIEW))
			return ROUTS.REVIEW_MEDIA
		else if (this.permission.includes(ROLE.MANAGE_CALENDAR_CAN_VIEW))
			return ROUTS.CALENDAR_VIEW
		else if (this.permission.includes(ROLE.MANAGE_OWNERS_CAN_MANAGE) || this.permission.includes(ROLE.MANAGE_OWNERS_CAN_VIEW))
			return ROUTS.MANAGE_OWNER
		else if (this.permission.includes(ROLE.MANAGE_ENROLLMENTS_CAN_MANAGE) || this.permission.includes(ROLE.MANAGE_ENROLLMENTS_CAN_VIEW))
			return ROUTS.MANAGE_ENROLLMENTS
		else if (this.permission.includes(ROLE.MANAGE_TRAINERS_CAN_MANAGE) || this.permission.includes(ROLE.MANAGE_TRAINERS_CAN_VIEW))
			return ROUTS.MANAGE_TRAINER
		else if (this.permission.includes(ROLE.MANAGE_FRANCHISE_CAN_MANAGE) || this.permission.includes(ROLE.MANAGE_FRANCHISE_CAN_VIEW))
			return ROUTS.FRANCHISE
		else if (this.permission.includes(ROLE.MANAGE_TERRITORY_CAN_MANAGE) || this.permission.includes(ROLE.MANAGE_TERRITORY_CAN_VIEW))
			return ROUTS.TERRITORY
		else if (this.permission.includes(ROLE.MANAGE_ACCOUTANT_CAN_MANAGE) || this.permission.includes(ROLE.MANAGE_ACCOUTANT_CAN_VIEW))
			return ROUTS.MANAGE_ACCOUNTANT
		else if (this.permission.includes(ROLE.MANAGE_USER_CAN_MANAGE) || this.permission.includes(ROLE.MANAGE_USER_CAN_VIEW))
			return ROUTS.USER
		else if (this.permission.includes(ROLE.MANAGE_PROGRAMS_CAN_MANAGE) || this.permission.includes(ROLE.MANAGE_PROGRAMS_CAN_VIEW))
			this.router.navigateByUrl('/' + ROUTS.MANAGE_PROGRAM);
		else if (this.permission.includes(ROLE.MANAGE_MESSAGES_CAN_MANAGE) || this.permission.includes(ROLE.MANAGE_MESSAGES_CAN_VIEW))
			return ROUTS.MESSAGES
		else if (this.permission.includes(ROLE.MANAGE_PAYROLL_CAN_VIEW))
			return ROUTS.PAYROLL
		else if (this.permission.includes(ROLE.MANAGE_EXPENSES_CAN_MANAGE) || this.permission.includes(ROLE.MANAGE_EXPENSES_CAN_VIEW))
			return ROUTS.MANAGE_EXPENSES
		else if (this.permission.includes(ROLE.MANAGE_SETTINGS_CAN_MANAGE) || this.permission.includes(ROLE.MANAGE_SETTINGS_CAN_VIEW))
			return ROUTS.SYSTEM_SETTING
		else if (this.permission.includes(ROLE.MANAGE_ROLE_CAN_MANAGE) || this.permission.includes(ROLE.MANAGE_ROLE_CAN_VIEW))
			return ROUTS.MANAGE_ROLE
		else if (this.permission.includes(ROLE.MANAGE_SUPPORT_CAN_MANAGE) || this.permission.includes(ROLE.MANAGE_SUPPORT_CAN_VIEW))
			return ROUTS.SUPPORT
	}

	redirectErrorPage(errorStatus) {

		if (errorStatus == 0) {


		} else if (errorStatus == 403) {


		} else if (errorStatus == 404) {


		} else if (errorStatus == 401) {

			this.removeData();

		} else {

		}
		ROUTS.CALENDAR_VIEW
	}

	/**
	 * Get date format
	 */
	getDateFormat() {

		if (!localStorage.hasOwnProperty(CONSTANTS.DATE_FORMAT))
			localStorage.setItem(CONSTANTS.DATE_FORMAT, CONSTANTS.DATE_FORMAT_DEFAULT);

		return localStorage.getItem(CONSTANTS.DATE_FORMAT);

	}

	/**
	 * Get time format
	 */
	getTimeFormat() {

		if (!localStorage.hasOwnProperty(CONSTANTS.TIME_FORMAT))
			localStorage.setItem(CONSTANTS.TIME_FORMAT, CONSTANTS.TIME_FORMAT_DEFAULT);

		return localStorage.getItem(CONSTANTS.TIME_FORMAT);

	}

	/**
	 * Get date time format
	 */
	getDateTimeFormat() {

		if (!localStorage.hasOwnProperty(CONSTANTS.DATE_TIME_FORMAT))
			localStorage.setItem(CONSTANTS.DATE_TIME_FORMAT, CONSTANTS.DATE_TIME_FORMAT_DEFAULT);

		return localStorage.getItem(CONSTANTS.DATE_TIME_FORMAT);

	}

	/**
	 * Get duration format
	 */
	getDurationFormat() {

		if (!localStorage.hasOwnProperty(CONSTANTS.DURATION_FORMAT))
			localStorage.setItem(CONSTANTS.DURATION_FORMAT, CONSTANTS.DURATION_FORMAT_DEFAULT);

		return localStorage.getItem(CONSTANTS.DURATION_FORMAT);

	}

	/**
	 * Get date range from date
	 */
	getDateRange(fromDate, toDate) {

		if (fromDate.getFullYear() == toDate.getFullYear()) {

			if (fromDate.getMonth() == (toDate.getMonth())) {

				if (fromDate.getDate() == (toDate.getDate()))
					return this.getDateStringDD_MMM_YYYY_FromLocalDate(fromDate);

				return this.getStringDDFromLocalDate(fromDate) + " to "
					+ this.getDateStringDD_MMM_YYYY_FromLocalDate(toDate);
			}

			return this.getStringDD_MMFromLocalDate(fromDate) + " to "
				+ this.getDateStringDD_MMM_YYYY_FromLocalDate(toDate);
		}

		return this.getDateStringDD_MMM_YYYY_FromLocalDate(fromDate) + " to "
			+ this.getDateStringDD_MMM_YYYY_FromLocalDate(toDate);

	}

	/**
	 * Convert date to dd MMM, yyyy date format
	 */
	getDateStringDD_MMM_YYYY_FromLocalDate(localDate) {
		return this.datePipe.transform(localDate, 'dd MMM yyyy');
	}

	/**
	 * Convert date to dd date format
	 */
	getStringDDFromLocalDate(localDate) {
		return this.datePipe.transform(localDate, 'dd');
	}

	/**
	 * Convert date to dd MMM format
	 */
	getStringDD_MMFromLocalDate(localDate) {
		return this.datePipe.transform(localDate, 'dd MMM');
	}

	/**
	 * Check user permission and if not have permission then redirect to not-authorized page
	 */
	checkPermission(routsUrl) {

		let permissionList = this.getCookie(CONSTANTS.COOKIE_USER_PERMISSION).split(',');
		let userRole = this.getCookie(CONSTANTS.COOKIE_USER_ROLE);

		if ((userRole == 'ROLE_CUSTOMER_USER' && permissionList.indexOf(routsUrl) == -1)) {
		}
	}

	/**
	 * Get list of permission from permissionDtoList
	 */
	getUserWisePermission(userPermissionList) {

		let pemissionList = [];

		for (let i = 0; i < userPermissionList.length; i++) {
			pemissionList.push(userPermissionList[i].permission);
		}

		this.setCookie(CONSTANTS.COOKIE_USER_PERMISSION, pemissionList, CONSTANTS.LOGIN_COOKIE_EXPIRATION_DAYS);

		return pemissionList;
	}

	//validate all form fields
	validateAllFormFields(formGroup: FormGroup) {
		Object.keys(formGroup.controls).forEach(field => {
			const control = formGroup.get(field);
			if (control instanceof FormControl) {
				control.markAsTouched({ onlySelf: true });
			} else if (control instanceof FormGroup) {
				this.validateAllFormFields(control);
			}
		});
	}

	getLoggedInUserName() {
		return this.getCookie(CONSTANTS.COOKIE_USER_NAME);
	}

	setDogData(dogData: any) {
		localStorage.setItem(CONSTANTS.DOG_DATA, JSON.stringify(dogData));
	}

	getDogData() {
		return JSON.parse(localStorage.getItem(CONSTANTS.DOG_DATA));
	}

	checkLoginUser() {

		let currentUser = this.decrypt(this.getCookie(CONSTANTS.COOKIE_ACCESSTOKEN));
		if (currentUser == null)
			this.router.navigate([ROUTS.SIGN_IN]);
	}

	setAuthorizationDataInCookieForByPass(results, role) {
		this.setCookie(CONSTANTS.COOKIE_USER_ROLE, results.role, CONSTANTS.LOGIN_COOKIE_EXPIRATION_DAYS);
		this.setCookie(CONSTANTS.COOKIE_USER_ROLE_LABEL, results.roleLabel, CONSTANTS.LOGIN_COOKIE_EXPIRATION_DAYS);
		this.setCookie(CONSTANTS.COOKIE_LAST_ROLE, role, CONSTANTS.LOGIN_COOKIE_EXPIRATION_DAYS);
		this.setCookie(CONSTANTS.COOKIE_PERMISSION, results.permissions, CONSTANTS.LOGIN_COOKIE_EXPIRATION_DAYS);
		let encryptedToken: string = this.encrypt(results.access_token);
		let refreshToken: string = this.encrypt(results.refresh_token);

		//Save the access token and path in Cookie
		this.setCookie(CONSTANTS.COOKIE_ACCESSTOKEN, encryptedToken, CONSTANTS.LOGIN_COOKIE_EXPIRATION_DAYS);
		this.setCookie(CONSTANTS.COOKIE_REFRESHTOKEN, refreshToken, CONSTANTS.LOGIN_COOKIE_EXPIRATION_DAYS);
		this.setCookie(CONSTANTS.COOKIE_USER_NAME, results.name, CONSTANTS.LOGIN_COOKIE_EXPIRATION_DAYS);
		//localStorage.setItem(CONSTANTS.EMAIL, results.email);

	}

	removeDataForByPass() {
		this.removeCookie(CONSTANTS.COOKIE_ACCESSTOKEN);
		this.removeCookie(CONSTANTS.COOKIE_REFRESHTOKEN);
		this.removeCookie(CONSTANTS.COOKIE_USER_ROLE);
		this.removeCookie(CONSTANTS.COOKIE_LAST_ROLE);
		this.removeCookie(CONSTANTS.COOKIE_USER_ROLE_LABEL);
		this.removeCookie(CONSTANTS.COOKIE_SUSPEND_WARNING);
		//localStorage.removeItem(CONSTANTS.EMAIL);
		localStorage.removeItem('byPass');
	}

	checkLoginByRole() {
		return new Promise((resolve) => {
			if (this.checkIsNullOrUndefined(this.getCookie(CONSTANTS.COOKIE_USER_ROLE))) {
				resolve("");
				return false;
			}
			resolve("");
			return true;
		});
	}

	/**
	 * logout time remove cookie data 
	 */
	setUsernameForProfileUpdateInCookie(fullname) {
		return new Promise((resolve) => {

			this.setCookie(CONSTANTS.COOKIE_USER_NAME, fullname, CONSTANTS.LOGIN_COOKIE_EXPIRATION_DAYS);

			resolve("");
		});
	}
	getPermissionList() {
		return this.getCookie(CONSTANTS.COOKIE_PERMISSION).split(',');
	}

	public get ROLE(): typeof ROLE {
		return ROLE;
	}

	checkPermissions(permissionsToCheck: string[], permissionList: string[]): boolean {
		for (const permission of permissionsToCheck) {
			if (permissionList.includes(permission)) {
				return true;
			}
		}
		return false;
	}

	isValidValue(value: any) {
		if (value == null || value == undefined || value == '' || value?.length <= 0)
			return false;
		else
			return true;
	}

	isValidAmount(value: any, type: string): boolean {
		if (type == 'amount') {
			return this.amountPattern.test(value);
		} else if (type == 'digit') {
			return this.numericPattern.test(value);
		} else {
			return false;
		}
	}

	dayDifferenceBetweenDates(startDate: Date, endDate: Date): number {

		// Calculate the number of milliseconds between the two dates
		const millisecondsPerDay = 24 * 60 * 60 * 1000;
		const differenceInMilliseconds = endDate.getTime() - startDate.getTime();

		// Calculate the number of days by dividing the difference in milliseconds by the milliseconds per day
		const numberOfDays = Math.round(differenceInMilliseconds / millisecondsPerDay);

		return numberOfDays;
	}

	/**
		Show success or error alert message
	 */
	showMessage(message, icon) {
		const Toast = Swal.mixin({
			toast: true,
			position: 'top',
			showConfirmButton: false,
			timer: icon == 'error' ? 7000 : 2000,
			timerProgressBar: true,
			didOpen: (toast) => {
				toast.addEventListener('mouseenter', Swal.stopTimer)
				toast.addEventListener('mouseleave', Swal.resumeTimer)
			}
		})
		Toast.fire({
			icon: icon,
			title: message
		})
	}

	getFullName(firstName: any, lastName: any) {
		if (lastName == null || lastName == undefined || lastName == '' || lastName == "")
			return firstName;
		else
			return firstName + ' ' + lastName;
	}

}