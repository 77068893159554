import { Injectable } from '@angular/core';
import { RouterModule, Routes, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Http, Response, RequestOptions, Headers, URLSearchParams } from '@angular/http';
import { catchError } from 'rxjs/operators';

import { APIClientService } from '../service/APIClientService';
import { CommonService } from '../service/common.service';
import * as URL from '../constants/url';
import { CONSTANTS } from '../constants/constant';
import * as ROUTS from '../constants/routs';


declare var $: any;

@Injectable()
export class DogService {

	constructor(public router: Router, private httpClient: HttpClient, public apiClientService: APIClientService, public commonService: CommonService) { }

	/**
	 * Gets the list of all Veterinarian
	 */
	getAllVeterinarian(): Observable<any> {

		return this.apiClientService.doGet(URL.GET_ALL_VETERINARIAN);
	}

	/**
	 * Add Veterinarian
	 */
	addVeterinarian(addVeterinarianForm: any): Observable<any> {
		return this.apiClientService.doPost(URL.ADD_VETERINARIAN, addVeterinarianForm);
	}


	/**
	* Gets the list of all dogs for owner
	*/
	getAllOwnerActiveDogs(dogName , start , length , orderColumn , order): Observable<any> {

		let urlSearchParams = new URLSearchParams();
		
		urlSearchParams.set('dogName', dogName);	
		urlSearchParams.set('start', start);
		urlSearchParams.set('length', length);
		urlSearchParams.set('orderColumn', orderColumn);
		urlSearchParams.set('order', order);

		return this.apiClientService.doPostWithHttp(URL.GET_ALL_OWNER_DOGS , urlSearchParams);
	}

	/**
	 * Gets the data of dog info and program imfo by id
	 */
	getdogProfileDataById(dogProgramId): Observable<any> {

		return this.apiClientService.doGet(URL.GET_DOG_PROFILE_BY_ID + '/' + dogProgramId);
	}

	/**
	 * Gets the data of dog diary list by programId
	*/
	getDiaryListByDogProgramId(dogProgramId): Observable<any> {

		return this.apiClientService.doGet(URL.GET_DIARY_LIST + '/' + dogProgramId);
	}

	/**
	* Gets the data of dog diary by programId
	*/
	getdogDiary(dogProgramId): Observable<any> {

		return this.apiClientService.doGet(URL.GET_DIARY + '/' + dogProgramId);
	}


	/**
	 * Gets the data of dog profile by programId and date
	*/
	getdogDiaryFromDateAndTrainerId(date, dogTrainerId, dogOldTrainerId): Observable<any> {

		return this.apiClientService.doGet(URL.GET_DIARY_FROM_DATE + '/' + dogTrainerId + '/' + dogOldTrainerId + '/' + date);
	}

	/**
	 * Get the media of dog diary by programId and date 
	 */
	getdogMediaFromDateAndProgramId(date, dogTrainerId, dogOldTrainerId): Observable<any> {
		return this.apiClientService.doGet(URL.GET_MEDIA_FROM_DATE + '/' + dogTrainerId + '/' + dogOldTrainerId + '/' + date);
	}

	/**
	 * Add or update note
	 */
	addNote(addNoteForm: any): Observable<any> {
		return this.apiClientService.doPost(URL.ADD_NOTE, addNoteForm);
	}

	/**
	 * Upload images 
	 */
	uploadImages(addNoteForm: any, attachFiles: any): Observable<any> {

		const formData = new FormData();

		formData.append("param", new Blob([JSON.stringify(addNoteForm)], {
			type: "application/json"
		}));
		attachFiles.forEach(function(file) {
			formData.append("files", file);
		});
		return this.apiClientService.doPost(URL.UPLOAD_FILE, formData);
	}

	/**
	  * Deletes the customersubscription by customerSubscriptionId
	  */
	deleteDogDiary(dogProgramId, date): Observable<any> {
		return this.apiClientService.doDelete(URL.DELETE_DOG_DIARY + '/' + dogProgramId + '/' + date);
	}

	/**
	 *
	 */
	deleteMedia(mediaId) {
		return this.apiClientService.doDelete(URL.DELETE_MEDIA + '/' + mediaId);
	}

	/**
	 *
	 */
	deleteNotUploadedMedia(mediaList) {
		return this.apiClientService.doDelete(URL.DELETE_NOT_UPLOADED_MEDIA + '/' + mediaList);
	}
	/**
	 * check date is valid or not for given programId
	*/
	isValidDate(date, dogTrainerId, existingDate): Observable<any> {

		return this.apiClientService.doGet(URL.IS_VALID_DATE + '/' + dogTrainerId + '/' + date + '/' + existingDate);
	}

	getAllActiveDogsByOwner(ownerId): Observable<any> {

		return this.apiClientService.doGet(URL.GET_ALL_DOG_BY_OWNER + '/' + ownerId);
	}

	/**
	 * Update Media Caption 
	 */
	updateMediaCaption(diaryMedia: any,): Observable<any> {
		return this.apiClientService.doPost(URL.UPDATE_MEDIA_CAPTION, diaryMedia);
	}


	/**
	 * Update Image Rotation
	 */
	updateImageRotation(mediaId: any, attachFiles: any): Observable<any> {

		const formData = new FormData();

		formData.append("param", new Blob([JSON.stringify(mediaId)], {
			type: "application/json"
		}));

		formData.append("files", attachFiles);

		return this.apiClientService.doPost(URL.UPDATE_IMAGE_ROTATION, formData);
	}

	/**
	 * Get Video Count for video upload progress
	 */
	getvideoCountByTrainerId(dogTrainerId: any, dogDairyDate: any): Observable<any> {
		return this.apiClientService.doGet(URL.GET_VIDEO_COUNT + '/' + dogTrainerId + '/' + dogDairyDate);
	}

	/**
	 * Gets the list of all dogs for trainer
	 */
	getAllTrinerDogs(isShowArchivedDog,dogName , start , length , orderColumn , order): Observable<any> {

		let urlSearchParams = new URLSearchParams();
		
		urlSearchParams.set('dogName', dogName);	
		urlSearchParams.set('start', start);
		urlSearchParams.set('length', length);
		urlSearchParams.set('orderColumn', orderColumn);
		urlSearchParams.set('order', order);

		return this.apiClientService.doPostWithHttp(URL.GET_ALL_TRAINER_DOGS + '/' + isShowArchivedDog , urlSearchParams);
	}

}