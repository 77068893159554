import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule, FormGroup } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpModule } from '@angular/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DatePipe,KeyValuePipe } from '@angular/common';
import { LoginRepository } from './repository/login.repository';
import { CommonRepository } from './repository/common.repository';
import { LayoutService } from './service/layout.service';
import { DogService } from './service/dog.service';
import { ReviewmediaService } from './service/reviewmedia.service';
import { TrainerService } from './service/trainer.service';
import { PagerService } from './service/pagerService';
import { VerifyUserService } from './service/verifyUser.service';
import { SetPasswordService } from './service/setPassword.service';
import { ProgramService } from './service/program.service';
import { BasicAuthInterceptor } from './interceptor/basic-auth.interceptor';
import { ErrorInterceptor } from './interceptor/error.interceptor';
import * as ROUTS from './constants/routs';
import { AppComponent } from './app.component';
import { CommonService } from './service/common.service';
import { APIClientService } from './service/APIClientService';
import { NgxMaskModule, IConfig } from 'ngx-mask'
import { NgxImageCompressService } from 'ngx-image-compress';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgSelectConfig } from '@ng-select/ng-select'; import { ɵs } from '@ng-select/ng-select';
import { MessagesService } from './service/messages.service';
import { FranchiseService } from './service/franchise.service';
import { UserService } from './service/user.service';
import { TimeService } from './service/time.service';
import { SupportService } from './service/support.service';
import { USPSValidationServiceService } from './service/uspsvalidation-service.service';
import { TitleCasePipe } from './admin/manage-role/title-case-pipe.pipe'; 
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;


@NgModule({
	declarations: [
		AppComponent,
		TitleCasePipe
	],
	imports: [
		BrowserModule,
		RouterModule.forRoot(ROUTS.routes, { relativeLinkResolution: 'legacy' }),
		FormsModule,
		HttpModule,
		ReactiveFormsModule,
		HttpClientModule,
		BrowserAnimationsModule,
		NgxMaskModule.forRoot(),
		NgSelectModule,
		NgbModule
	],
	providers: [DatePipe,
		{ provide: HTTP_INTERCEPTORS, useClass: BasicAuthInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
		LoginRepository, CommonRepository, CommonService, APIClientService, LayoutService, DogService, ReviewmediaService, TrainerService,
		PagerService, VerifyUserService, SetPasswordService, ProgramService, NgxImageCompressService, MessagesService, FranchiseService, UserService,TimeService,
		SupportService,USPSValidationServiceService,
		NgSelectConfig, ɵs],
	bootstrap: [AppComponent]
})
export class AppModule { }
