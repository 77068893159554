import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Http, Response, RequestOptions, Headers, URLSearchParams } from '@angular/http';
import { catchError } from 'rxjs/operators';

import { APIClientService } from '../service/APIClientService';
import { CommonService } from '../service/common.service';
import *as URL from '../constants/url';
import { CONSTANTS } from '../constants/constant';
import *as ROUTS from '../constants/routs';

declare var $: any;

@Injectable()
export class SetPasswordService {

	constructor(private httpClient: HttpClient, public apiClientService: APIClientService, public commonService: CommonService) { }

	/**
	 * set password
	 */
	setPassword(userId, password, isVerified): Observable<any> {

		let urlSearchParams = new URLSearchParams();

		urlSearchParams.set('userId', userId);
		urlSearchParams.set('password', password);
		urlSearchParams.set('isVerified', isVerified);

		return this.apiClientService.doPostWithOutAuth(URL.SET_PASSWORD, urlSearchParams);
	}


	/**
	 * forgot password
	 */
	forgotPassword(email: any): Observable<any> {

		let urlSearchParams = new URLSearchParams();

		urlSearchParams.set('email', email);

		return this.apiClientService.doPostWithOutAuth(URL.FORGOT_PASSWORD, urlSearchParams);

	}

	/**
	 * chnage password
	 */
	changePassword(changePasswordRequest: any): Observable<any> {

		return this.apiClientService.doPost(URL.CHANGE_ADMIN_PASSWORD, changePasswordRequest);

	}

	/**
	*  check use is verified or not
	*/
	userIsVerified(userId): Observable<any> {

		return this.apiClientService.doGetWithOutAuth(URL.CHECK_IS_USER_VERIFIYED + '/' + userId);
	}


	/**
	*  resend password link on email
	*/
	resendPasswordLink(userId: any): Observable<any> {

		return this.apiClientService.doGet(URL.RESEND_PASSWORD_LINK + '/' + userId);

	}

}