import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { APIClientService } from './APIClientService';
import { CommonService } from './common.service';
import { Http, Response, RequestOptions, Headers, URLSearchParams } from '@angular/http';
import * as URL from '../constants/url';

@Injectable({
  providedIn: 'root'
})
@Injectable()
export class FranchiseService {

  constructor(public router: Router, private httpClient: HttpClient, public apiClientService: APIClientService, public commonService: CommonService) { }

  /**
   * Gets the data of single trainer by id
   */

  getFranchiseListPageWise(start, length, orderColumn, order, name,territoryName, franchiseOwnerName,number,email, showDeletedFranchise): Observable<any> {

    let urlSearchParams = new URLSearchParams();
    urlSearchParams.set('start', start);
    urlSearchParams.set('length', length);
    urlSearchParams.set('orderColumn', orderColumn);
    urlSearchParams.set('order', order);

    urlSearchParams.set('name', name);
    urlSearchParams.set('territoryName', territoryName);
    urlSearchParams.set('franchiseOwnerName', franchiseOwnerName);
    urlSearchParams.set('number', number);
    urlSearchParams.set('email', email);
    
    urlSearchParams.set('showDeletedFranchise', showDeletedFranchise);

    return this.apiClientService.doPostWithHttp(URL.GET_PAGE_WISE_FRANCHISE, urlSearchParams);
  }

  /**
   * Add trainer
   */
  saveFranchise(franchiseRequest: any): Observable<any> {
    return this.apiClientService.doPost(URL.SAVE_FRANCHISE, franchiseRequest);
  }

  /**
  * Delete franchise by id
  */
  deleteFranchise(userId, status,territoryIdList): Observable<any> {
      return this.apiClientService.doPost(URL.DELETE_FRANCHIS + '/' + userId + '/' + status,territoryIdList);
  }

  /**
 * Get franchise by id
 */
  getFranchiseById(franchiseId): Observable<any> {

    return this.apiClientService.doGet(URL.GET_FRANCHISE_BY_ID + '/' + franchiseId);
  }

  // getAllActiveFranchiseWithoutTerritory(id: any): Observable<any> {
  //   if (id) {
  //     return this.apiClientService.doGet(URL.GET_ALL_ACTIVE_FRANCHISE + "?territoryId=" + id);
  //   } else {
  //     return this.apiClientService.doGet(URL.GET_ALL_ACTIVE_FRANCHISE);
  //   }
  // }

  getAllActiveFranchise(): Observable<any> {
    return this.apiClientService.doGet(URL.GET_ALL_ACTIVE_FRANCHISE);
  }

  	/** check Email Is Already Exist */
	checkEmailIsAlreadyExistForFranchise(id, email): Observable<any> {
		let urlSearchParams = new URLSearchParams();

		urlSearchParams.set('id', id);
		urlSearchParams.set('email', email);

		return this.apiClientService.doPostWithHttp(URL.CHECK_EMAIL_IS_ALREADY_EXIST_FOR_FRANCHISE, urlSearchParams);

	}

	/** check Email Is Already Exist */
	checkEmailIsAlreadyExist(id, email): Observable<any> {
		let urlSearchParams = new URLSearchParams();

		urlSearchParams.set('id', id);
		urlSearchParams.set('email', email);

		return this.apiClientService.doPostWithHttp(URL.CHECK_EMAIL_IS_ALREADY_EXIST, urlSearchParams);

	}

  /** check Name Is Already Exist */
	checkFranchiseNameIsAlreadyExist(id, name): Observable<any> {
		let urlSearchParams = new URLSearchParams();

		urlSearchParams.set('id', id);
		urlSearchParams.set('name', name);

		return this.apiClientService.doPostWithHttp(URL.CHECK_FRANCHISE_NAME_IS_ALREADY_EXIST, urlSearchParams);

	}

}
