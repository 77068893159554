import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as URL from '../constants/url';
import { APIClientService } from './APIClientService';
import { URLSearchParams } from '@angular/http';
import { AnyForUntypedForms } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(public apiClientService: APIClientService) { }

  /**
   * Add user
   */
  saveUser(userRequest: any): Observable<any> {
    return this.apiClientService.doPost(URL.SAVE_USER, userRequest);
  }

  getUserListPageWise(franchiseId, start, length, orderCol, order, name, number, territoryName, role, email, showDeletedFranchise, franchiseName): Observable<any> {

    let urlSearchParams = new URLSearchParams();
    urlSearchParams.set('franchiseId', franchiseId);
    urlSearchParams.set('start', start);
    urlSearchParams.set('length', length);
    urlSearchParams.set('orderColumn', orderCol);
    urlSearchParams.set('order', order);
    urlSearchParams.set('name', name);
    urlSearchParams.set('number', number);
    urlSearchParams.set('territoryName', territoryName);
    urlSearchParams.set('role', role);
    urlSearchParams.set('email', email);
    urlSearchParams.set('showDeletedFranchise', showDeletedFranchise);
    urlSearchParams.set('franchiseName', franchiseName);

    return this.apiClientService.doPostWithHttp(URL.GET_USER_LIST_PAGE_WISE, urlSearchParams);
  }

  deleteManageUser(userId, status): Observable<any> {

    return this.apiClientService.doDelete(URL.DELETE_USER + '/' + userId + '/' + status);
  }

  getUserById(userId): Observable<any> {
    return this.apiClientService.doGet(URL.GET_USER_BY_ID + '/' + userId);
  }

  findAllTerritoryManagerByFranchise(franchiseId: any): Observable<any> {

    return this.apiClientService.doGet(URL.FIND_ALL_TERRITORY_MANAGER_BY_FRANCHISE + "/" + franchiseId);
  }

  getUserForFranchiseOwnerById(userId: any): Observable<any> {
    return this.apiClientService.doGet(URL.GET_USER_FOR_FRANCHISEOWNER_BY_ID + '/' + userId);
  }
}
