import { HttpClient } from '@angular/common/http';
import { Http, Response, RequestOptions, Headers, URLSearchParams } from '@angular/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { APIClientService } from './APIClientService';
import { CommonService } from './common.service';
import * as URL from '../constants/url';
import { EventEmitter } from '@angular/core';


@Injectable()
export class MessagesService {
	

	totalNewMessages = new EventEmitter();
	isShowManageMessageFeature = new EventEmitter();
	isShowSupportFeature = new EventEmitter();

	constructor(public router: Router, private httpClient: HttpClient, public apiClientService: APIClientService, public commonService: CommonService) { }

	/**
	* Get all messages for owner / trainer  by dogtrainerId
	*/
	getMesagesByDogTrainerIdAndUserId(dogId,dogProgramId,userId, isShowArchivedDog): Observable<any> {
		let urlSearchParams = new URLSearchParams();
		urlSearchParams.set('dogProgramId', dogProgramId);
		urlSearchParams.set('userId', userId);
		urlSearchParams.set('dogId', dogId);
		urlSearchParams.set('isShowArchivedDog', isShowArchivedDog);
		
		return this.apiClientService.doPostWithHttp(URL.GET_ALL_MESSAGES_OF_OWNER_OR_TRAINER , urlSearchParams);
	}

	/**
	 *  Save messsages
	 */
 	sendMessage(messageFrom: any): Observable<any> {
		return this.apiClientService.doPost(URL.SEND_MESSAGES, messageFrom);
	}

	/**
	* Get all messages for admin
	*/
	getAllMesagesByDogId(dogId,messageStatus): Observable<any> {
		return this.apiClientService.doGet(URL.GET_ALL_MESSAGES_OF_ADMIN  + '/' + dogId + '/' + messageStatus);
	}

	/**
	* Get message by id
	*/
	seenMessage(messageId): Observable<any> {
		return this.apiClientService.doGet(URL.SEEN_MESSAGES + '/' + messageId);
	}

	/**
	* Approve message by id
	*/
	approveMessage(messageId): Observable<any> {
		let urlSearchParams = new URLSearchParams();
		urlSearchParams.set('messageId', messageId);
		
		return this.apiClientService.doPostWithHttp(URL.APPROVE_MESSAGES , urlSearchParams);
	}

	/**
	* Approve message by id
	*/
	approveAllMessages(messageIdList): Observable<any> {
		
		return this.apiClientService.doPostWithHttp(URL.APPROVE_ALL_MESSAGES , messageIdList);
	}
	
	/**
	* Approve message by id
	*/
	disApproveMessage(messageId): Observable<any> {
		let urlSearchParams = new URLSearchParams();
		urlSearchParams.set('messageId', messageId);
		
		return this.apiClientService.doPostWithHttp(URL.DIS_APPROVE_MESSAGES , urlSearchParams);
	}
	
	/**
	* Gets the list of all owner and trainers
 	*/
	getAllMessageMedia(messageStatus, trainerId, dogId, territoryId): Observable<any> {
		let urlSearchParams = new URLSearchParams();

		urlSearchParams.set('messageStatus', messageStatus);
		urlSearchParams.set('trainerId', trainerId);
		urlSearchParams.set('dogId', dogId);
		urlSearchParams.set('territoryId', territoryId);

	return this.apiClientService.doPostWithHttp(URL.GET_ALL_MESSAGES_BY_FILTER, urlSearchParams);
	}

	/**
	* Update message by id
	*/
	updateMessage(messageId,message,status): Observable<any> {
		let urlSearchParams = new URLSearchParams();
		urlSearchParams.set('messageId', messageId);
		urlSearchParams.set('message', message);
		urlSearchParams.set('status', status);
		
		return this.apiClientService.doPostWithHttp(URL.UPDATE_MESSAGES , urlSearchParams);
	}
	
	
	/**
	 * Gets the list of all dogs for owners
	 */
	 getAllOwnerDogsByFilter(dogName): Observable<any> {

		return this.apiClientService.doGet(URL.GET_ALL_OWNER_DOGS_BY_FILTER+"?dogName="+dogName);
	}
	
	/**
	 * Gets the list of all dogs for trainer
	 */
	  getAllTrinerDogs(isShowArchivedDog , dogName): Observable<any> {
		return this.apiClientService.doGet(URL.GET_ALL_TRAINER_DOGS_FOR_MESSAGES+ '/' + isShowArchivedDog + "?dogName="+dogName);
	}
	
	emitTotalNewMessageEvent(newMessageCount: any) {
		this.totalNewMessages.emit(newMessageCount);
	}
	
	getTotalNewMessageEvent() {
		return this.totalNewMessages;
	}

	/**
	* Update message and message status by id
	*/
	updateMessageAndMessageStatus(messageId, message, messageStatus): Observable<any> {
		let urlSearchParams = new URLSearchParams();
		urlSearchParams.set('messageId', messageId);
		urlSearchParams.set('message', message);
		urlSearchParams.set('messageStatus', messageStatus);

		return this.apiClientService.doPostWithHttp(URL.UPDATE_MESSAGES_AND_MESSAGE_STATUS, urlSearchParams);
	}

	/**
	* Delete messages by id list
	*/
	deleteAllMessages(messageIdList, message, messageStatus): Observable<any> {
		let urlSearchParams = new URLSearchParams();
		urlSearchParams.set('messageId', messageIdList);
		urlSearchParams.set('message', message);
		urlSearchParams.set('messageStatus', messageStatus);

		return this.apiClientService.doPostWithHttp(URL.DELETE_ALL_MESSAGES, urlSearchParams);
	}

	/**
	* Reject message by id
	*/
	rejectMessage(messageId, rejectionMessage): Observable<any> {
		let urlSearchParams = new URLSearchParams();
		urlSearchParams.set('messageId', messageId);
		urlSearchParams.set('rejectionMessage', rejectionMessage);

		return this.apiClientService.doPostWithHttp(URL.REJECT_MESSAGES, urlSearchParams);
	}

	/**
	* Reject all messages by id
	*/
	rejectAllMessages(messageIdList, rejectionMessage): Observable<any> {
		let urlSearchParams = new URLSearchParams();
		urlSearchParams.set('messageIdList', messageIdList);
		urlSearchParams.set('rejectionMessage', rejectionMessage);

		return this.apiClientService.doPostWithHttp(URL.REJECT_ALL_MESSAGES, urlSearchParams);
	}

	/**
	 * 
	 * @param isShowManageMessageFeature to show manage message module in layout panel
	 */
	 emitIsShowMessageEvent(isShowManageMessageFeature: any) {
		 console.log('inside emit')
		this.isShowManageMessageFeature.emit(isShowManageMessageFeature);
	}

	/**
	 * 
	 * @param isShowSupportFeature to show manage message module in layout panel
	 */
	 emitIsShowSupportEvent(isShowSupportFeature: any) {
		this.isShowSupportFeature.emit(isShowSupportFeature);
	}

	getIsShowMessageEvent() {
		return this.isShowManageMessageFeature;
	}
	
	getIsShowSupportEvent() {
		return this.isShowSupportFeature;
	}

	/**
	 *  Save messsages
	 */
 	seenAllMessages(listOfMessageRequest: any): Observable<any> {
		return this.apiClientService.doPost(URL.SEEN_ALL_MESSAGES, listOfMessageRequest);
	}


}
