import { Injectable } from '@angular/core';
import { RouterModule, Routes, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Http, Response, RequestOptions, Headers, URLSearchParams } from '@angular/http';
import { catchError } from 'rxjs/operators';

import { APIClientService } from '../service/APIClientService';
import { CommonService } from '../service/common.service';
import * as URL from '../constants/url';
import { CONSTANTS } from '../constants/constant';
import * as ROUTS from '../constants/routs';


declare var $: any;

@Injectable()
export class LayoutService {

	constructor(public router: Router, private httpClient: HttpClient, public apiClientService: APIClientService, public commonService: CommonService) { }

	/**
	 * logOut
	 */
	logOut(): Observable<any> {
		sessionStorage.clear();
		return this.apiClientService.doGet(URL.LOGOUT);
	}

	/**
	 * Save User Profile Pic 
	 */
	saveProfilePicture(userId: any, file: any, isDelete: any): Observable<any> {
		let formData = new FormData();
		formData.append("userId", userId);
		formData.append("files", file);
		formData.append("isDelete", isDelete);
		return this.apiClientService.doPostWithHttp(URL.UPLOAD_PROFILE_FILE, formData);
	}

	/**
	 * Update User Profile
	 */
	updateProfileDetail(userProfileData): Observable<any> {

		return this.apiClientService.doPostWithHttp(URL.UPDATE_PROFILE_DETAIL, userProfileData);

	}

	/**
	 * get Logged in user
	 */
	getLoggedInUser(): Observable<any> {
		return this.apiClientService.doGet(URL.GET_LOGED_USER);
	}

}