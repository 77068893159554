import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../auth/auth.guard';

//export const HOME = '';
export const SIGN_IN = 'signin';
export const LAYOUT = '';
export const MY_DOGS = 'mydogs';
export const DOG_PROFILE = 'dogProfile/:id';
export const TRAINER_DOG_DIARY = 'trainerDogDiary/:id/:tid/:date/:media';
export const REVIEW_MEDIA = 'reviewMedia';
export const MANAGE_OWNER = 'manageOwner';
export const MANAGE_TRAINER = 'manageTrainer';
export const ADD_TRAINER = 'manageTrainer/addTrainer';
export const UPDATE_TRAINER = 'manageTrainer/addTrainer/:id';
export const ADD_OWNER = 'manageOwner/addOwner';

export const ADD_EXPENSES = 'manageExpenses/addExpenses';
export const UPDATE_EXPENSES = 'manageExpenses/addExpenses/:id';

export const UPDATE_OWNER = 'manageOwner/addOwner/:id';
export const VERIFY_USER = 'verifyUser/:userId/:status';
export const SET_PASSWORD = 'setPassword/:userId/:status';
export const MANAGE_PROGRAM = 'manageProgram';
export const ADD_PROGRAM = 'manageProgram/addProgram';
export const UPDATE_PROGRAM = 'manageProgram/addProgram/:id';
export const FORGOT_PASSWORD = 'forgotPassword';
export const CHANGE_PASSWORD = 'changePassword';
export const MANAGE_ENROLLMENTS = 'manageEnrollments';
export const ADD_ENROLLMENT = 'manageEnrollments/addEnrollment';
export const ADD_SUBMIT_ENROLLMENT = 'manageEnrollments/addEnrollment/:id/:tid';
export const UPDATE_ENROLLMENT = 'manageEnrollments/updateEnrollment/:id/:tid';
export const OWNER_DOG_DIARY = 'ownerDogDiary/:id/:tid/:date';
export const CALENDAR_VIEW = 'calendar';
export const PAYROLL = 'payroll';
export const PAYMENT_HISTORY = 'paymentHistory/:customerId';
export const MANAGE_EXPENSES = 'manageExpenses';
export const TRAINER_MANAGE_EXPENSES = 'manageExpenses/:id';
export const MANAGE_ACCOUNTANT = 'manageAccountant';
export const ADD_ACCOUNTANT = 'manageAccountant/addAccountant';
export const UPDATE_ACCOUNTANT = 'manageAccountant/addAccountant/:id';
export const SYSTEM_SETTING = 'systemSetting';
export const MANAGE_MESSAGES = 'manageMessages';
export const MESSAGES = 'messages';
export const FRANCHISE = 'franchise';
export const ADD_FRANCHISE = 'addFranchise';
export const UPDATE_FRANCHISE = 'addFranchise/:id';
export const ADD_TERRITORY = 'addTerritory';
export const TERRITORY = 'territory';
export const UPDATE_TERRITORY = "territory/addTerritory/:id";
export const USER = 'user';
export const ADD_USER = 'addUser';
export const UPDATE_MANAGE_USER = 'addUser/:id';
export const MANAGE_ROLE = 'manageRole';
export const UPDATE_MANAGE_ROLE = 'manageRole/updateManageRole/:id';
export const ADD_MANAGE_ROLE = 'manageRole/updateManageRole';
export const SUPPORT = 'support'
export const SUPPORT_ADD_TICKET = 'support/addTicket';
export const SUPPORT_EDIT_TICKET = 'support/addTicket/:id';
export const EDIT_PROFILE = 'editProfile/:id';
export const PERSONS = 'persons';
export const LEADS = 'leads';
export const DEALS = 'deals';
export const LEAD_DETAILS = 'leadDetails/:id';

/**
 * All routs
 */
export const routes: Routes = [
	{ path: "", loadChildren: () => import('./submodules/login.module').then(m => m.LoginModule) },
	{ path: SIGN_IN, loadChildren: () => import('./submodules/login.module').then(m => m.LoginModule) },
	{ path: LAYOUT, loadChildren: () => import('./submodules/layout.module').then(m => m.LayoutModule) },
	{ path: MY_DOGS, loadChildren: () => import('./submodules/assigneddogs.module').then(m => m.AssigneddogsModule) },
	{ path: DOG_PROFILE, loadChildren: () => import('./submodules/dogprofile.module').then(m => m.DogprofileModule) },
	{ path: TRAINER_DOG_DIARY, loadChildren: () => import('./submodules/dogdiary.module').then(m => m.DogdiaryModule) },
	{ path: REVIEW_MEDIA, loadChildren: () => import('./submodules/reviewmedia.module').then(m => m.ReviewmediaModule) },
	{ path: MANAGE_OWNER, loadChildren: () => import('./submodules/owner.module').then(m => m.OwnerModule) },
	{ path: MANAGE_TRAINER, loadChildren: () => import('./submodules/trainer.module').then(m => m.TrainerModule) },
	{ path: ADD_OWNER, loadChildren: () => import('./submodules/addowner.module').then(m => m.AddownerModule) },
	{ path: UPDATE_OWNER, loadChildren: () => import('./submodules/addowner.module').then(m => m.AddownerModule) },
	{ path: ADD_TRAINER, loadChildren: () => import('./submodules/addtrainer.module').then(m => m.AddtrainerModule) },
	{ path: UPDATE_TRAINER, loadChildren: () => import('./submodules/addtrainer.module').then(m => m.AddtrainerModule) },
	{ path: VERIFY_USER, loadChildren: () => import('./submodules/verifyUser.module').then(m => m.VerifyUserModule) },
	{ path: SET_PASSWORD, loadChildren: () => import('./submodules/setPassword.module').then(m => m.SetPasswordModule) },
	{ path: MANAGE_PROGRAM, loadChildren: () => import('./submodules/program.module').then(m => m.ProgramModule) },
	{ path: ADD_PROGRAM, loadChildren: () => import('./submodules/addprogram.module').then(m => m.AddprogramModule) },
	{ path: UPDATE_PROGRAM, loadChildren: () => import('./submodules/addprogram.module').then(m => m.AddprogramModule) },
	{ path: FORGOT_PASSWORD, loadChildren: () => import('./submodules/forgotPassword.module').then(m => m.ForgotPasswordModule) },
	{ path: CHANGE_PASSWORD, loadChildren: () => import('./submodules/changePassword.module').then(m => m.ChangePasswordModule) },
	{ path: MANAGE_ENROLLMENTS, loadChildren: () => import('.//submodules/enrollments.module').then(m => m.EnrollmentsModule) },
	{ path: ADD_ENROLLMENT, loadChildren: () => import('./submodules/addenrollment.module').then(m => m.AddEnrollmentModule) },
	{ path: ADD_SUBMIT_ENROLLMENT, loadChildren: () => import('./submodules/addenrollment.module').then(m => m.AddEnrollmentModule) },
	{ path: UPDATE_ENROLLMENT, loadChildren: () => import('./submodules/updateenrollment.module').then(m => m.UpdateenrollmentModule) },
	{ path: OWNER_DOG_DIARY, loadChildren: () => import('./submodules/ownerDogdiary.module').then(m => m.OwnerDogdiaryModule) },
	{ path: CALENDAR_VIEW, loadChildren: () => import('./submodules/calendarview.module').then(m => m.CalendarViewModule) },
	{ path: PAYROLL, loadChildren: () => import('./submodules/payroll.module').then(m => m.PayrollModule) },
	{ path: PAYMENT_HISTORY, loadChildren: () => import('./submodules/paymentHistory.module').then(m => m.PaymentHistoryModule) },
	{ path: MANAGE_EXPENSES, loadChildren: () => import('./submodules/expenses.module').then(m => m.ExpensesModule) },
	{ path: ADD_EXPENSES, loadChildren: () => import('./submodules/addexpenses.module').then(m => m.AddExpensesModule) },
	{ path: UPDATE_EXPENSES, loadChildren: () => import('./submodules/addexpenses.module').then(m => m.AddExpensesModule) },
	{ path: TRAINER_MANAGE_EXPENSES, loadChildren: () => import('./submodules/expenses.module').then(m => m.ExpensesModule) },
	{ path: ADD_ACCOUNTANT, loadChildren: () => import('./submodules/addaccountant.module').then(m => m.AddAccountantModule) },
	{ path: UPDATE_ACCOUNTANT, loadChildren: () => import('./submodules/addaccountant.module').then(m => m.AddAccountantModule) },
	{ path: MANAGE_ACCOUNTANT, loadChildren: () => import('./submodules/accountant.module').then(m => m.AccountantModule) },
	/*{ path: SYSTEM_SETTING, loadChildren: './constants/submodules/systemsetting.module#SystemSettingModule' },*/
	{
		path: SYSTEM_SETTING,
		loadChildren: () => import('./submodules/systemsetting.module').then(m => m.SystemSettingModule)
	},
	{
		path: MANAGE_MESSAGES,
		loadChildren: () => import('./submodules/manageMessages.module').then(m => m.ManageMessagesModule)
	},
	{
		path: MESSAGES,
		loadChildren: () => import('./submodules/messages.module').then(m => m.MessagesModule)
	},
	{
		path: FRANCHISE,
		loadChildren: () => import('./submodules/franchise.module').then(m => m.FranchiseModule)
	},
	{
		path: ADD_FRANCHISE,
		loadChildren: () => import('./submodules/addfranchise.module').then(m => m.AddFranchiseModule)
	},
	{
		path: UPDATE_FRANCHISE,
		loadChildren: () => import('./submodules/addfranchise.module').then(m => m.AddFranchiseModule)
	},
	{
		path: ADD_TERRITORY,
		loadChildren: () => import('./submodules/addterritory.module').then(m => m.AddTerritoryModule)
	},
	{
		path: TERRITORY,
		loadChildren: () => import('./submodules/territory.module').then(m => m.TerritoryModule)
	},
	{
		path: UPDATE_TERRITORY,
		loadChildren: () => import('./submodules/addterritory.module').then(m => m.AddTerritoryModule)
	},
	{
		path: USER,
		loadChildren: () => import('./submodules/user.module').then(m => m.ManageUserModule)
	},
	{
		path: ADD_USER,
		loadChildren: () => import('./submodules/adduser.module').then(m => m.AddUserModule)
	},
	{
		path: UPDATE_MANAGE_USER,
		loadChildren: () => import('./submodules/adduser.module').then(m => m.AddUserModule)
	},
	{
		path: MANAGE_ROLE,
		loadChildren: () => import('./submodules/managerole.module').then(m => m.ManageRoleModule)
	},
	{
		path: UPDATE_MANAGE_ROLE,
		loadChildren: () => import('./submodules/updatemanagerole.module').then(m => m.UpdateManageRoleModule)
	},
	{
		path: SUPPORT,
		loadChildren: () => import('./submodules/support.module').then(m => m.SupportModule)
	},
	{
		path: SUPPORT_ADD_TICKET,
		loadChildren: () => import('./submodules/addsupport.module').then(m => m.AddSupportModule)
	},
	{
		path: SUPPORT_EDIT_TICKET,
		loadChildren: () => import('./submodules/addsupport.module').then(m => m.AddSupportModule)
	},
	{ path: EDIT_PROFILE, loadChildren: () => import('./submodules/editProfile.module').then(m => m.EditProfileModule) },
	{ path: PERSONS, loadChildren: () => import('./submodules/person.module').then(m => m.PersonModule) },
	{ path: LEADS, loadChildren: () => import('./submodules/lead.module').then(m => m.LeadModule) },
	{ path: DEALS, loadChildren: () => import('./submodules/deal.module').then(m => m.DealModule) },
	{ path: LEAD_DETAILS, loadChildren: () => import('./submodules/lead-details.module').then(m => m.LeadDetailsModule) }


	/* { path: ERROR, loadChildren: './constants/submodules/errorPage.module#ErrorPageModule'}*/
];