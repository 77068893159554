export class CONSTANTS {

	public static MAX_FILE_UPLOAD_SIZE_LIMIT = 4000000;
	public static COOKIE_ACCESSTOKEN = "puppyAccessToken";
	public static COOKIE_REFRESHTOKEN = "puppyRrefreshToken";
	public static COOKIE_ENCRYPTED_ID = "encryptedId";
	public static COOKIE_USER_ROLE = "puppyUserRole";
	public static COOKIE_SUSPEND_WARNING = "puppySuspendWarning";
	public static COOKIE_USER_ROLE_LABEL = "puppyUserRoleLabel";
	public static COOKIE_USER_NAME = "userName";
	public static COOKIE_COMPANY_NAME = "companyName";
	public static USER = "user";
	public static LANGUAGE = "language";
	public static LANGUAGE_DEFAULT = "english";
	public static COOKIE_USER_PERMISSION = "userPermission";
	public static CUSTOMER_NAME_FOR_DETAIL = "customerNameForDetail";
	public static REMEMBER_ME_FLAG: string = "rememberMeFlag";
	public static COMPANY_LOGO: string = "companyLogo";
	public static DEVICE_QR_CODE: string = "deviceQRCode";
	public static WORKING_DAYS = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
	public static DATE_FORMAT = "dateFormat";
	public static TIME_FORMAT = "timeFormat";
	public static DATE_TIME_FORMAT = "dateTimeFormat";
	public static DURATION_FORMAT = "durationFormat";
	public static DATE_FORMAT_DEFAULT = "dd MMM yyyy";
	public static TIME_FORMAT_DEFAULT = "hh:mm a";
	public static DATE_TIME_FORMAT_DEFAULT = "dd MMM yyyy, hh:mm a";
	public static DURATION_FORMAT_DEFAULT = "00h 00m 00s";
	public static IS_ANY_DEVICE_CONNECTED = "isAnyDeviceConnected";
	public static TRUE = "true";
	public static EMAIL = "puppyEmail";
	public static CUSTOMER_TYPE = "customerType";
	public static SUBSCRIPTION_TYPE = "subscriptionType";
	public static PREVIOUS_LINK = "previousLink";
	public static PREVIOUS_LINK_EMAIL_NOTIFICATION_TAB = "/userSetting/emailNotification/9P8GiIh_xgS5dfbpvnkh9w";
	public static LEFT_MENU_OPEN_FLAG = "leftMenuOpenFlag";
	public static SUPPORT_TITLE = "supportTitle";
	public static REPOER_IS_ACTIVE = "reportIsActive";
	public static TIME_FORMAT_HH_MM = "HH:mm";
	public static IS_REDIRECTED_TO_VIEWLEAD = "isRedirectedToViewLead";
	public static IS_REDIRECTED_TO_PERIODIC_REPORTS = "isRedirectedToPeriodicReports";
	public static COOKIE_LAST_ROLE = "roleType";

	public static MONDAY = "Monday";
	public static TUESDAY = "Tuesday";
	public static WEDNESDAY = "Wednesday";
	public static THURSDAY = "Thursday";
	public static FRIDAY = "Friday";
	public static SATURDAY = "Saturday";
	public static SUNDAY = "Sunday";
	public static EMAIL_PATTERN = /^[_0-9A-Za-z][_0-9A-Za-z-.]+@([0-9A-Za-z][0-9A-Za-z-]+\.)+[A-Za-z]+$/;
	public static PHONE_PATTERN = /^\s*(?:\+?(\d{1,3}))?[- (]*(\d{3})[- )]*(\d{3})[- ]*(\d{4})(?: *[x/#]{1}(\d+))?\s*$/;


	//    public static LOGIN_COOKIE_EXPIRATION_DAYS : number = 60 * 60 * 24 * 7;

	public static LOGIN_COOKIE_EXPIRATION_DAYS: number = 60 * 60 * 24 * 365; // 1 year
	public static ENCRYPT_DECRYPT_SECRET_KEY: string = "1234567890";
	public static PATH_OF_COOKIE: string = "pathOfCookie";
	public static CRYPTO_SECRET_KEY = 'cryptoSecretKey';
	public static PERMISSIONS = 'permissions';
	public static USER_ROLE = 'role';
	public static PAGE_NUMBER_LIMIT_OPTIONS = [10, 15, 20];
	public static BRAND: string = 'brand';
	public static BRAND_ID: string = 'brandId';
	public static SUBMIT: string = 'Submit';
	public static SUBMITTING: string = 'Submitting...';
	public static LOGO_MIN_WIDTH: number = 100;
	public static LOGO_MIN_HEIGHT: number = 100;
	public static MAX_FILE_SIZE: number = 10000000;
	public static DATE_RANGE_DURATION: number = 120;

	//Uploading constants
	public static blockUIMessage: string = 'Please wait while uploading data, it may take several minutes.'
	public static invalidCsvMessage: string = 'Invalid csv file'

	//TIMEZONE RELATED CONSTANTS
	public static TIME_ZONE: string = 'timeZone'
	public static HEADER_TIME_ZONE: string = 'Time-Zone'

	//FILE TYPE
	public static CSV_FILE_TYPE: string = 'text/csv'
	public static CSV_TYPE: string = 'CSV'
	public static PDF_TYPE: string = 'PDF'

	public static CALLYZER_BIZ_PLAYSTORE_LINK: string = 'https://play.google.com/store/apps/details?id=com.websoptimization.callyzerbiz'

	public static DOG_DATA: string = 'dog';

	public static MAX_IMAGE_FILE_UPLOAD_SIZE_LIMIT = 10485760;  // MAX SIZE FOR SINGLE IMAGE in Bytes Only equivalent to 10MB 
	public static MAX_TOTAL_IMAGE_FILE_UPLOAD_SIZE_LIMIT = 104857600;  // MAX SIZE FOR LIST OF IMAGES in Bytes Only equivalent to 100MB

	public static MAX_VIDEO_FILE_UPLOAD_SIZE_LIMIT = 268435456000;  // MAX SIZE FOR SINGLE VIDEO in Bytes Only equivalent to 250MB 
	public static MAX_TOTAL_VIDEO_FILE_UPLOAD_SIZE_LIMIT = 1073741824;  // MAX SIZE FOR LIST OF VIDEOS in Bytes Only equivalent to 1000GB

	public static COOKIE_PERMISSION = 'permission';
}
