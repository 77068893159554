import { Injectable } from '@angular/core';

import { Http, Response, ResponseContentType } from '@angular/http';
import { Headers, RequestOptions, URLSearchParams } from '@angular/http';
import { Observable, Subject} from 'rxjs';
import { map, take } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import { Cookie } from 'ng2-cookies';
import { CommonService } from '../service/common.service';
import { CONSTANTS } from '../constants/constant';

import * as URL from '../constants/url';

@Injectable()
export class CommonRepository {
    
    constructor(private http: Http,
        public commonService: CommonService) { }
    
    /**
     * Get access token from cookie
     */
    getToken() : string {
//    	return Cookie.get(CONSTANTS.COOKIE_ACCESSTOKEN);
        return this.commonService.decrypt(Cookie.get(CONSTANTS.COOKIE_ACCESSTOKEN));
    }
    
    getRefreshToken() : string {
//    	return Cookie.get(CONSTANTS.COOKIE_ACCESSTOKEN);
        return this.commonService.decrypt(Cookie.get(CONSTANTS.COOKIE_REFRESHTOKEN));
    }
    

    /**
     * Get common request header with authorization token for content type 'URLEncoded'
     */
    getCommonRequestOptionsForURLEncoded(authorization : string) : RequestOptions{
    	
        let headers = new Headers();
        headers.append('Authorization',(authorization == URL.AUTHORIZATION ? authorization : authorization + this.getToken()));
        headers.append('Content-Type', 'application/x-www-form-urlencoded');
        this.setheadersforFormatAndLanguage(headers);
        return new RequestOptions({ headers: headers });
    }
    
     /**
     * Get common request header with authorization token for content type 'URLEncoded'
     */
    getCommonRequestOptionsWithoutContentType(authorization : string) : RequestOptions{
        
        let headers = new Headers();
        headers.append('Authorization',(authorization == URL.AUTHORIZATION ? authorization : authorization + this.getToken()));
        this.setheadersforFormatAndLanguage(headers);
        return new RequestOptions({ headers: headers });
    }
    
    /**
     * Get common request header with authorization token for content type 'URLEncoded'
     */
    getCommonRequestOptionsForRefreshToken(authorization : string) : RequestOptions{
    	
        let headers = new Headers();
        headers.append('Authorization',(authorization == URL.AUTHORIZATION ? authorization : authorization + this.getRefreshToken()));
        headers.append('Content-Type', 'application/x-www-form-urlencoded');
        this.setheadersforFormatAndLanguage(headers);
        return new RequestOptions({ headers: headers });
    }
    
    /**
     * Get common request header with set ResponseContentType as Blob
     * 
     */
    getCommonRequestOptionsWithoutContentTypeWithResponseTypeBlob(authorization : string) : RequestOptions{
    	
    	let headers = new Headers();
    	headers.append('Authorization',(authorization == URL.AUTHORIZATION ? authorization : authorization + this.getToken()));
    	headers.append('Accept', 'application/pdf');
    	this.setheadersforFormatAndLanguage(headers);
    	return new RequestOptions({ headers: headers, responseType: ResponseContentType.Blob });
    }
    
    /**
     * Set header for date/time/datetime/duration format and language
     */
    setheadersforFormatAndLanguage(headers){
    	
    	headers.append('Accept-Language', this.commonService.getLanguage());
    	headers.append('Date-Format', this.commonService.getDateFormat());
        headers.append('Time-Format', this.commonService.getTimeFormat());
//        headers.append('DateTime-Format', this.commonService.getDateTimeFormat());
        headers.append('Duration-Format', this.commonService.getDurationFormat());
        
        return headers;
    	
    }
}