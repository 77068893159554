import { Component } from '@angular/core';
import { Router, ActivatedRoute,RoutesRecognized, ParamMap } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
constructor(private router: Router) {}
  title = 'puppy-kindergarten-angualr';
}
