import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APIClientService } from '../service/APIClientService';
import *as URL from '../constants/url';
import { Http, Response, RequestOptions, Headers, URLSearchParams } from '@angular/http';

@Injectable()
export class SupportService {

	constructor(private httpClient: HttpClient, public apiClientService: APIClientService) { }

	totalNewTickets = new EventEmitter();

	/**
	  * Get all tickets as per ticket status
	  */
	getAllTicket(ticketStatus, searchFilterValue, territoryIds): Observable<any> {
		let urlSearchParams = new URLSearchParams();
		urlSearchParams.set('ticketStatus', ticketStatus);
		urlSearchParams.set('searchFilterValue', searchFilterValue);
		urlSearchParams.set('territoryIds', territoryIds);

		return this.apiClientService.doPostWithHttp(URL.GET_ALL_TICKET, urlSearchParams);
	}

	/**
	  * Get all tickets for owner or trainer
	  */
	getAllTicketForOwnerOrTrainer(ticketStatus, loginUserId, searchFilterValue): Observable<any> {
		let urlSearchParams = new URLSearchParams();
		urlSearchParams.set('ticketStatus', ticketStatus);
		urlSearchParams.set('loginUserId', loginUserId);
		urlSearchParams.set('searchFilterValue', searchFilterValue);

		return this.apiClientService.doPostWithHttp(URL.GET_ALL_TICKET_FOR_OWNER_OR_TRAINER, urlSearchParams);
	}

	/**
	 * Get ticket by ticket id
	*/
	getTicketById(ticketId): Observable<any> {
		return this.apiClientService.doGet(URL.GET_TICKET_BY_ID + '/' + ticketId);
	}

	/*update status*/

	updateTicketStatus(ticketId, ticketStatus): Observable<any> {

		let urlSearchParams = new URLSearchParams();
		urlSearchParams.set('ticketId', ticketId);
		urlSearchParams.set('ticketStatus', ticketStatus);


		return this.apiClientService.doPostWithHttp(URL.UPDATE_TICKET_STATUS, urlSearchParams);

	}

	sendComment(commentForm: any, attachment: any): Observable<any> {
		const formData = new FormData();

		formData.append("param", new Blob([JSON.stringify(commentForm)], {
			type: "application/json"
		}));
		attachment.forEach(function(file) {
			formData.append("files", file);
		});
		return this.apiClientService.doPost(URL.SEND_COMMENTS_AND_ATTACHMENT, formData);
	}

	/**
	* Get ticket by ticket id
   */
	getUnreadTicketCount(territoryIds): Observable<any> {
		let urlSearchParams = new URLSearchParams();
		urlSearchParams.set('territoryIds', territoryIds);

		return this.apiClientService.doPostWithHttp(URL.GET_UNREAD_TICKET_COUNT, urlSearchParams);
	}

	seenFlag(ticketId,loginUserId): Observable<any> {

		let urlSearchParams = new URLSearchParams();
		urlSearchParams.set('ticketId', ticketId);
		urlSearchParams.set('loginUserId', loginUserId);

		return this.apiClientService.doPostWithHttp(URL.SEEN_FALG , urlSearchParams);
	}

	  /**
	 *
	 * @param newTicketCount to show ticket count in layout panel
	 */
	emitTotalTicketEvent(newTicketCount: any) {

		this.totalNewTickets.emit(newTicketCount);
	}

	getTotalTicketEvent() {

		return this.totalNewTickets;
	}
}
