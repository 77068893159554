import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Http, Response, RequestOptions, Headers, URLSearchParams } from '@angular/http';
import { catchError } from 'rxjs/operators';

import { APIClientService } from '../service/APIClientService';
import { CommonService } from '../service/common.service'; 
import *as URL from '../constants/url';
import { CONSTANTS } from '../constants/constant';
import *as ROUTS from '../constants/routs';

declare var $: any;

@Injectable()
export class VerifyUserService {

	constructor(private httpClient: HttpClient, public apiClientService: APIClientService, public commonService: CommonService) { }

	/**
	 * verify user
	 */
	verifyUser(userId): Observable<any> {

		return this.apiClientService.doGetWithOutAuth(URL.VERIFY_USER + '/' + userId);
	}

}