import { Injectable } from '@angular/core';
import { RouterModule, Routes, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Http, Response, RequestOptions, Headers, URLSearchParams } from '@angular/http';
import { catchError } from 'rxjs/operators';

import { APIClientService } from '../service/APIClientService';
import { CommonService } from '../service/common.service';
import * as URL from '../constants/url';
import { CONSTANTS } from '../constants/constant';
import * as ROUTS from '../constants/routs';


declare var $: any;

@Injectable()
export class ProgramService {

	constructor(public router: Router, private httpClient: HttpClient, public apiClientService: APIClientService, public commonService: CommonService) { }

	/**
	 * Delete program
	 */
	deleteProgram(programId, franchiseId): Observable<any> {
		if (franchiseId) {
			return this.apiClientService.doDelete(URL.DELETE_PROGRAM + '/' + programId + '?franchiseId=' + franchiseId);
		} else {
			return this.apiClientService.doDelete(URL.DELETE_PROGRAM + '/' + programId);
		}
	}

	/**
	 * Add program
	 */
	saveProgram(programRequest: any): Observable<any> {

		return this.apiClientService.doPost(URL.ADD_PROGRAM, programRequest);

	}

	/**
	 * Gets the data of single program by id
	 */
	getProgramData(programId): Observable<any> {

		return this.apiClientService.doGet(URL.GET_PROGRAM_BY_ID + '/' + programId);
	}

	/**
	 * Restore program
	 */
	restoreProgram(programId): Observable<any> {

		return this.apiClientService.doGet(URL.RESTORE_PROGRAM + '/' + programId);
	}

	/**
	 * get program listing for paginaiton
	 */
	getProgramListPageWise(searchColName, searchColValue, start, length, orderCol, order, showDeletedPrograms, franchiseId): Observable<any> {

		let urlSearchParams = new URLSearchParams();

		if (searchColName != undefined && searchColName != null && searchColValue != undefined && searchColValue != null
			&& searchColName.length > 0 && searchColValue.length > 0 && searchColName.length == searchColValue.length) {

			urlSearchParams.set('searchColName', searchColName);
			urlSearchParams.set('searchColValue', searchColValue);
		}

		urlSearchParams.set('start', start);
		urlSearchParams.set('length', length);
		urlSearchParams.set('orderColumn', orderCol);
		urlSearchParams.set('order', order);
		urlSearchParams.set('showDeletedPrograms', showDeletedPrograms);
		urlSearchParams.set('franchiseId', franchiseId);
		return this.apiClientService.doPostWithHttp(URL.GET_PAGE_WISE_PROGRAM, urlSearchParams);
	}

	getAllProgramListByFranchise(franchiseId): Observable<any> {
		return this.apiClientService.doGet(URL.GET_ALL_PROGRAM_BY_FRANCHISE + '?franchiseId=' + franchiseId);
	}

}