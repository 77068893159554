export enum ROLE{
	
	SUPER_ADMIN = 'SUPER_ADMIN',
	OWNER = 'OWNER',
	TRAINER = 'TRAINER',  
	FRANC_ACCOUNTANT = 'ACCOUNTANT', 
	SYSTEM_ACCOUNTANT = 'SYSTEM_ACCOUNTANT', 
	FRANC_OWNER = 'FRANCHISE_OWNER', 
	FRANC_TRAINER = 'FRANC_TRAINER',
	FRANC_ADMIN = 'FRANCHISE_ADMIN',
	TERR_MANAGER = 'TERRITORY_MANAGER',

	// Permissions
	MANAGE_TERRITORY_CAN_MANAGE = 'MANAGE_TERRITORY_CAN_MANAGE',
	MANAGE_TERRITORY_CAN_VIEW = 'MANAGE_TERRITORY_CAN_VIEW',

	MANAGE_USER_CAN_MANAGE = 'MANAGE_USER_CAN_MANAGE',
	MANAGE_USER_CAN_VIEW = 'MANAGE_USER_CAN_VIEW',

	MANAGE_FRANCHISE_CAN_VIEW = 'MANAGE_FRANCHISE_CAN_VIEW',
	MANAGE_FRANCHISE_CAN_MANAGE = 'MANAGE_FRANCHISE_CAN_MANAGE',
	
	MANAGE_ACCOUTANT_CAN_VIEW = 'MANAGE_ACCOUTANT_CAN_VIEW',
	MANAGE_ACCOUTANT_CAN_MANAGE = 'MANAGE_ACCOUTANT_CAN_MANAGE',
	
	MANAGE_ROLE_CAN_MANAGE = 'MANAGE_ROLE_CAN_MANAGE',
	MANAGE_ROLE_CAN_VIEW = 'MANAGE_ROLE_CAN_VIEW',

	MANAGE_EXPENSES_CAN_MANAGE = 'MANAGE_EXPENSES_CAN_MANAGE',
	MANAGE_EXPENSES_CAN_VIEW = 'MANAGE_EXPENSES_CAN_VIEW',

	MANAGE_ENROLLMENTS_CAN_MANAGE = 'MANAGE_ENROLLMENTS_CAN_MANAGE',
	MANAGE_ENROLLMENTS_CAN_VIEW = 'MANAGE_ENROLLMENTS_CAN_VIEW',

	MANAGE_TRAINERS_CAN_MANAGE = 'MANAGE_TRAINERS_CAN_MANAGE',
	MANAGE_TRAINERS_CAN_VIEW = 'MANAGE_TRAINERS_CAN_VIEW',

	MANAGE_PROGRAMS_CAN_MANAGE = 'MANAGE_PROGRAMS_CAN_MANAGE',
	MANAGE_PROGRAMS_CAN_VIEW = 'MANAGE_PROGRAMS_CAN_VIEW',

	MANAGE_MESSAGES_CAN_MANAGE = 'MANAGE_MESSAGES_CAN_MANAGE',
	MANAGE_MESSAGES_CAN_VIEW = 'MANAGE_MESSAGES_CAN_VIEW',
	
	MANAGE_DOGS_CAN_MANAGE = 'MANAGE_DOGS_CAN_MANAGE',
	MANAGE_DOGS_CAN_VIEW = 'MANAGE_DOGS_CAN_VIEW',
	
	MANAGE_MEDIA_CAN_MANAGE = 'MANAGE_MEDIA_CAN_MANAGE',
	MANAGE_MEDIA_CAN_VIEW = 'MANAGE_MEDIA_CAN_VIEW',

	MANAGE_DOG_DIARY_CAN_MANAGE = 'MANAGE_DOG_DIARY_CAN_MANAGE',
	MANAGE_DOG_DIARY_CAN_VIEW = 'MANAGE_DOG_DIARY_CAN_VIEW',

	MANAGE_CALENDAR_CAN_MANAGE = 'MANAGE_CALENDAR_CAN_MANAGE',
	MANAGE_CALENDAR_CAN_VIEW = 'MANAGE_CALENDAR_CAN_VIEW',
	
	MANAGE_OWNERS_CAN_MANAGE = 'MANAGE_OWNERS_CAN_MANAGE',
	MANAGE_OWNERS_CAN_VIEW = 'MANAGE_OWNERS_CAN_VIEW',

	MANAGE_SETTINGS_CAN_MANAGE = 'MANAGE_SETTINGS_CAN_MANAGE',
	MANAGE_SETTINGS_CAN_VIEW = 'MANAGE_SETTINGS_CAN_VIEW',
	
	MANAGE_PAYROLL_CAN_MANAGE = 'MANAGE_PAYROLL_CAN_MANAGE',
	MANAGE_PAYROLL_CAN_VIEW= 'MANAGE_PAYROLL_CAN_VIEW',
	
	MANAGE_SUPPLIES_CAN_MANAGE = 'MANAGE_SUPPLIES_CAN_MANAGE',
	MANAGE_SUPPLIES_CAN_VIEW = 'MANAGE_SUPPLIES_CAN_VIEW',
	
	MANAGE_SUPPORT_CAN_MANAGE = 'MANAGE_SUPPORT_CAN_MANAGE',
	MANAGE_SUPPORT_CAN_VIEW = 'MANAGE_SUPPORT_CAN_VIEW',

	MANAGE_PERSONS_CAN_MANAGE = 'MANAGE_PERSONS_CAN_MANAGE',
	MANAGE_PERSONS_CAN_VIEW = 'MANAGE_PERSONS_CAN_VIEW',

	MANAGE_LEADS_CAN_MANAGE = 'MANAGE_LEADS_CAN_MANAGE',
	MANAGE_LEADS_CAN_VIEW = 'MANAGE_LEADS_CAN_VIEW',

	MANAGE_DEALS_CAN_MANAGE = 'MANAGE_DEALS_CAN_MANAGE',
	MANAGE_DEALS_CAN_VIEW = 'MANAGE_DEALS_CAN_VIEW',

}