import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class TimeService {

  constructor() { }

  setMessageTime(date) : string {
    //return moment(date).fromNow(true) + " " + "ago";
		return moment(date).utc(date).local().fromNow(true) + " " + "ago";
	}
}
