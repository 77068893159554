import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { RouterModule, Routes, Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, map, filter, tap } from 'rxjs/operators';
import Swal from 'sweetalert2';
import * as ROUTS from '../constants/routs';

import { CommonService } from '../service/common.service';

import { CONSTANTS } from '../constants/constant';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
	constructor(private router: Router, private commonService: CommonService) { }

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(request).pipe(catchError(err => {

			if (err.status == 401) {

				//localStorage.setItem(CONSTANTS.REMEMBER_ME_FLAG, "false");
				this.commonService.removeData();
				this.router.navigate([ROUTS.SIGN_IN]);
				this.showMessage("Your session is expired, please login again", 'error');
			}

			if (err.status == 403) {
			}

			if (err.status == 404) {
			}

			if (err.status == 0) {

			}

			return throwError(err.error);

		}));
	}

	showMessage(message, icon) {
		const Toast = Swal.mixin({
			toast: true,
			position: 'top',
			showConfirmButton: false,
			timer: icon == 'error' ? 7000 : 2000,
			timerProgressBar: true,
			didOpen: (toast) => {
				toast.addEventListener('mouseenter', Swal.stopTimer)
				toast.addEventListener('mouseleave', Swal.resumeTimer)
			}
		})
		Toast.fire({
			icon: icon,
			title: message
		})
	}
}