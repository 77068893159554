import { Injectable } from '@angular/core';
import { RouterModule, Routes, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Http, Response, RequestOptions, Headers, URLSearchParams } from '@angular/http';
import { catchError } from 'rxjs/operators';

import { APIClientService } from '../service/APIClientService';
import { CommonService } from '../service/common.service';
import * as URL from '../constants/url';
import { CONSTANTS } from '../constants/constant';
import * as ROUTS from '../constants/routs';


declare var $: any;

@Injectable()
export class TrainerService {

	constructor(public router: Router, private httpClient: HttpClient, public apiClientService: APIClientService, public commonService: CommonService) { }

	/**
	 * Deletes trainer
	 */
	deleteTrainer(userId, role): Observable<any> {
		return this.apiClientService.doDelete(URL.DELETE_TRAINER + '/' + userId + '/' + role);
	}

	/**
	 * Add trainer
	 */
	saveTrainer(userRequest: any): Observable<any> {
		return this.apiClientService.doPost(URL.ADD_TRAINER, userRequest);

	}

	/**
	 * Gets the data of single trainer by id
	 */
	getTrainerData(userId, role): Observable<any> {

		return this.apiClientService.doGet(URL.GET_TRAINER_DATA + '/' + userId + '/' + role);
	}

	/**
		 * get lead listing for paginaiton
		 */
	getTrainerListPageWise(searchColName, searchColValue, start, length, date, role, orderColumn, order, showDeletedOwnerOrTrainer, territoryId): Observable<any> {

		let urlSearchParams = new URLSearchParams();

		if (date != null) {

			urlSearchParams.set('date', date);
		}

		if (searchColName != undefined && searchColName != null && searchColValue != undefined && searchColValue != null
			&& searchColName.length > 0 && searchColValue.length > 0 && searchColName.length == searchColValue.length) {

			urlSearchParams.set('searchColName', searchColName);
			urlSearchParams.set('searchColValue', searchColValue);
		}

		urlSearchParams.set('start', start);
		urlSearchParams.set('length', length);
		urlSearchParams.set('role', role);
		urlSearchParams.set('orderColumn', orderColumn);
		urlSearchParams.set('order', order);
		urlSearchParams.set('showDeletedOwnerOrTrainer', showDeletedOwnerOrTrainer);
		urlSearchParams.set('territoryId', territoryId);

		return this.apiClientService.doPostWithHttp(URL.GET_PAGE_WISE_TRAINER, urlSearchParams);
	}
	getDogListPageWise(searchColName, searchColValue, start, length, date, role, orderColumn, order, showDeletedDogs, territoryId): Observable<any> {

		let urlSearchParams = new URLSearchParams();

		if (date != null) {

			urlSearchParams.set('date', date);
		}

		if (searchColName != undefined && searchColName != null && searchColValue != undefined && searchColValue != null
			&& searchColName.length > 0 && searchColValue.length > 0 && searchColName.length == searchColValue.length) {

			urlSearchParams.set('searchColName', searchColName);
			urlSearchParams.set('searchColValue', searchColValue);
		}

		urlSearchParams.set('start', start);
		urlSearchParams.set('length', length);
		urlSearchParams.set('role', role);
		urlSearchParams.set('orderColumn', orderColumn);
		urlSearchParams.set('order', order);
		urlSearchParams.set('showDeletedDogs', showDeletedDogs);
		urlSearchParams.set('territoryId', territoryId);

		return this.apiClientService.doPostWithHttp(URL.GET_PAGE_WISE_DOG, urlSearchParams);
	}

	getProgramList(): Observable<any> {

		return this.apiClientService.doGet(URL.GET_PROGRAM_DATA);
	}

	getProgramListFranchiseWise(franchiseId): Observable<any> {

		return this.apiClientService.doGet(URL.GET_ALL_PROGRAM_BY_FRANCHISE + '?franchiseId=' + franchiseId);
	}


	/**
	* get lead listing for paginaiton
	*/
	getDogTrainerListPageWise(searchColName, searchColValue, start, length, date, role, orderColumn, order, isShowArchiveEnrollment, territoryId): Observable<any> {

		let urlSearchParams = new URLSearchParams();

		// if(date != null){

		// 	urlSearchParams.set('date', date);
		// }

		if (searchColName != undefined && searchColName != null && searchColValue != undefined && searchColValue != null
			&& searchColName.length > 0 && searchColValue.length > 0 && searchColName.length == searchColValue.length) {

			urlSearchParams.set('searchColName', searchColName);
			urlSearchParams.set('searchColValue', searchColValue);
		}

		urlSearchParams.set('start', start);
		urlSearchParams.set('length', length);
		urlSearchParams.set('role', role);
		urlSearchParams.set('orderColumn', orderColumn);
		urlSearchParams.set('order', order);
		urlSearchParams.set('territoryId', territoryId);
		urlSearchParams.set('isShowArchiveEnrollment', isShowArchiveEnrollment);
		return this.apiClientService.doPostWithHttp(URL.GET_PAGE_WISE_DOG_TRAINER, urlSearchParams);
	}

	getAllOwnerList(): Observable<any> {

		return this.apiClientService.doGet(URL.GET_ALL_OWNER);
	}

	saveEnrollment(addEnrollmentRequest: any): Observable<any> {
		return this.apiClientService.doPost(URL.ADD_ENROLLMENT, addEnrollmentRequest);

	}

	saveDogTrainer(addDogTrainerRequest: any): Observable<any> {
		return this.apiClientService.doPost(URL.ADD_DOG_TRAINER, addDogTrainerRequest);

	}

	/**
	* Deletes Enrollment
	*/
	deleteEnrollment(dogTrainerId): Observable<any> {
		return this.apiClientService.doDelete(URL.DELETE_ENROLLMENT + '/' + dogTrainerId);
	}

	/**
	 * Deletes trainer
	 */
	deleteDogTrainer(dogTrainerId): Observable<any> {
		return this.apiClientService.doDelete(URL.DELETE_DOG_TRAINER + '/' + dogTrainerId);
	}

	/**
	 * Gets the data of single trainer by id
	 */
	getDogTrainerData(dogProgramId): Observable<any> {

		return this.apiClientService.doGet(URL.GET_DOG_TRAINER_BY_PROGRAM + '/' + dogProgramId);
	}


	getDogTrainerByProgram(programId): Observable<any> {

		return this.apiClientService.doGet(URL.GET_DOG_TRAINER_BY_PROGRAM + '/' + programId);
	}

	getDogTrainerByDogTriner(dogTrinerId): Observable<any> {

		return this.apiClientService.doGet(URL.GET_ENROLLMENT + '/' + dogTrinerId);
	}

	/**
	 * restore trainer
	 */
	restoreTrainer(userId, role, status): Observable<any> {
		return this.apiClientService.doDelete(URL.RESTORE_TRAINER + '/' + userId + '/' + role + '/' + status);
	}

	restoreDog(dogId, role, status): Observable<any> {
		return this.apiClientService.doDelete(URL.RESTORE_DOG + '/' + dogId + '/' + role + '/' + status);
	}

	getCurrentUser() {
		return this.apiClientService.doGet(URL.GET_CURRENT_USER);
	}

	getAllProgramList(): Observable<any> {

		return this.apiClientService.doGet(URL.GET_ALL_PROGRAM_DATA);
	}

	updateTotalDaysDogTrainer(addDogTrainerRequest: any): Observable<any> {
		return this.apiClientService.doPost(URL.UPDATE_TOTAL_DAYS_DOG_TRAINER, addDogTrainerRequest);

	}

	getCountTrainerActiveProgram(userId): Observable<any> {
		return this.apiClientService.doGet(URL.GET_COUNT_TRAINER_ACTIVE_PROGRAM + '/' + userId);
	}

	/**
	* Download Dog Pdf Report
	*/
	downloadDogReport(dogRequest: any): Observable<any> {
		return this.apiClientService.doPostWithHttpAndResponseTypeBlob(URL.DOWNLOAD_DOG_REPORT, dogRequest);
	}

	downloadDogDiaryReport(dogRequest: any): Observable<any> {
		return this.apiClientService.doPostWithHttpAndResponseTypeBlob(URL.DOWNLOAD_DOG_DIARY_REPORT, dogRequest);
	}

	/** get List of delivery status */
	deliveryStatusList(): Observable<any> {
		return this.apiClientService.doGet(URL.GET_DELIVERY_STATUS);
	}

	/** get enrollment data for calendar view */
	getEnrollmentDataForCalenderView(date, territoryId): Observable<any> {
		return this.apiClientService.doGet(URL.GET_ENROLLMENT_FOR_CALENDAR_VIEW + '/' + date + '/' + territoryId);
	}


	/** get all invoice data */
	getPayrollData(trainerId, startDate, endDate, territoryId): Observable<any> {

		let urlSearchParams = new URLSearchParams();

		urlSearchParams.set('trainerId', trainerId);
		urlSearchParams.set('startDate', startDate);
		urlSearchParams.set('endDate', endDate);
		urlSearchParams.set('territoryId', territoryId);

		return this.apiClientService.doPostWithHttp(URL.GET_PAYROLL_DATA, urlSearchParams);

	}

	/**
	* Download Payroll Pdf
	*/
	downloadPayrollPdf(payrollRequest: any): Observable<any> {
		return this.apiClientService.doPostWithHttpAndResponseTypeBlob(URL.DOWNLOAD_PAYROLL_PDF, payrollRequest);
	}

	/**
	 * Get all verified trainer list
	 */

	getAllVerifiedTrinerList(territoryId): Observable<any> {

		return this.apiClientService.doGet(URL.GET_ALL_VERIFIED_TRAINER + '/' + territoryId);
	}

	/**
		 * Get all expenses list
		 */

	getAllExpenseTypeList(territoryId): Observable<any> {

		if(territoryId){
			return this.apiClientService.doGet(URL.GET_ALL_EXPENSES_TYPES_TRAINER + '?territoryId=' + territoryId);
		}else{
			return this.apiClientService.doGet(URL.GET_ALL_EXPENSES_TYPES_TRAINER);
		}
	}

	/**
		 * Upload images
		 */
	saveExpenses(addExpensesForm: any, attachment: any): Observable<any> {

		const formData = new FormData();

		formData.append("param", new Blob([JSON.stringify(addExpensesForm)], {
			type: "application/json"
		}));

		formData.append("file", attachment);

		return this.apiClientService.doPost(URL.SAVE_EXPENSES, formData);

	}

	/**
			 * Save Expense Types
			 */
	saveExpenseType(expenseName: any, territoryId: any): Observable<any> {
		let urlSearchParams = new URLSearchParams();

		urlSearchParams.set('expenseName', expenseName);
		urlSearchParams.set('territoryId', territoryId);
		return this.apiClientService.doPostWithHttp(URL.SAVE_EXPENSE_TYPE, urlSearchParams);
	}

	/**
	 * get Stripe User
	 */
	getStripeUser(stripeEmail, stripeCustomerId): Observable<any> {
		return this.apiClientService.doGet(URL.GET_STRIPE_USER + '/' + stripeEmail + '/' + stripeCustomerId);
	}

	/**
	  * get Stripe User
	  */
	fetchStripeData(stripeEmail): Observable<any> {
		return this.apiClientService.doGet(URL.GET_STRIPE_USER + '/' + stripeEmail);
	}

	/**
	  * get Stripe User
	  */
	fetchStripeDataForIsEdit(stripeEmail, ownerId): Observable<any> {
		return this.apiClientService.doGet(URL.GET_STRIPE_USER_IS_EDIT + '/' + stripeEmail + '/' + ownerId);
	}

	/**
	 * get Transaction History
	 */
	getTransactionHistory(customerId): Observable<any> {
		return this.apiClientService.doGet(URL.GET_PAYMENT_HISTORY + '/' + customerId);
	}

	/**
	 * get expenses for paginaiton
	 */
	getExpensesListPageWise(searchColName, searchColValue, start, length, orderColumn, order, role, trainerId, territoryId): Observable<any> {

		let urlSearchParams = new URLSearchParams();

		if (searchColName != undefined && searchColName != null && searchColValue != undefined && searchColValue != null
			&& searchColName.length > 0 && searchColValue.length > 0 && searchColName.length == searchColValue.length) {

			urlSearchParams.set('searchColName', searchColName);
			urlSearchParams.set('searchColValue', searchColValue);
		}

		urlSearchParams.set('start', start);
		urlSearchParams.set('length', length); role
		urlSearchParams.set('orderColumn', orderColumn);
		urlSearchParams.set('order', order);
		urlSearchParams.set('role', role);
		urlSearchParams.set('trainerId', trainerId);
		urlSearchParams.set('territoryId', territoryId);

		return this.apiClientService.doPostWithHttp(URL.GET_PAGE_WISE_EXPENSES, urlSearchParams);
	}

	/**
	 * Delete Expenses
	 */
	deleteExpenses(expensesId): Observable<any> {
		return this.apiClientService.doDelete(URL.DELETE_EXPENSES + '/' + expensesId);
	}

	/**
	 * Get Expenses
	 */
	getExpenses(expensesId): Observable<any> {
		return this.apiClientService.doGet(URL.GET_EXPENSES + '/' + expensesId);
	}

	/** get Payroll Expenses Data */
	getPayrollExpensesData(trainerId, startDate, endDate, territoryId): Observable<any> {

		let urlSearchParams = new URLSearchParams();

		urlSearchParams.set('trainerId', trainerId);
		urlSearchParams.set('startDate', startDate);
		urlSearchParams.set('endDate', endDate);
		urlSearchParams.set('territoryId', territoryId);

		return this.apiClientService.doPostWithHttp(URL.GET_PAYROLL_EXPENSES_DATA, urlSearchParams);

	}

	/**
	 * Is Expenses Available
	 */
	isExpenseAvailable(trainerId, dogId): Observable<any> {
		return this.apiClientService.doGet(URL.IS_EXPENSES_AVAILABLE + '/' + trainerId + '/' + dogId);
	}

	/**
	 * Deletes All Expenses
	 */
	deleteAllExpenses(trainerId, dogId): Observable<any> {
		return this.apiClientService.doDelete(URL.DELETE_ALL_EXPENSES + '/' + trainerId + '/' + dogId);
	}

	/**
	 * isExpenseTypeExist
	 */
	isExpenseTypeExist(expenseName,territoryId): Observable<any> {
		return this.apiClientService.doGet(URL.IS_EXPENSE_EXIST + '/' + expenseName + '/' + territoryId);
	}

	/** check Email Is Already Exist */
	checkEmailIsAlreadyExist(id, email): Observable<any> {
		let urlSearchParams = new URLSearchParams();

		urlSearchParams.set('id', id);
		urlSearchParams.set('email', email);

		return this.apiClientService.doPostWithHttp(URL.CHECK_EMAIL_IS_ALREADY_EXIST, urlSearchParams);

	}


	checkDogDiaryFeature(featureName: string): Observable<any> {
		return this.apiClientService.doGet(URL.CHECK_SYSTEM_SETTING + '/' + featureName);
	}

	updateDogDiaryFeature(updateDogDiaryRequest): Observable<any> {
		return this.apiClientService.doPostWithHttp(URL.UPDATE_DOG_DIARY_FEATURE, updateDogDiaryRequest);
	}

	getAllOwnerListByTerritory(territoryId): Observable<any> {

		return this.apiClientService.doGet(URL.GET_ALL_OWNER_BY_TERRITORY + '/' + territoryId);
	}

	/**
	* Save Attachment
	*/
	saveTicket(ticketFormData: any, attachment: any): Observable<any> {

		const formData = new FormData();

		formData.append("param", new Blob([JSON.stringify(ticketFormData)], {
			type: "application/json"
		}));
		attachment.forEach(function (file) {
			formData.append("files", file);
		});
		return this.apiClientService.doPost(URL.SAVE_TICKET, formData);

	}

}
