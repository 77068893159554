import { Injectable } from '@angular/core';
import { APIClientService } from './APIClientService';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import * as URL from '../constants/url';

@Injectable({
  providedIn: 'root'
})
export class USPSValidationServiceService {

  constructor(public apiClientService: APIClientService, private httpClient: HttpClient) { }

  /**
   * this is a common service that will get city and state name 
   * by calling USPS Api.
   */
  getCityStateName(zipCode:any): Observable<any> {
    return this.apiClientService.doPost(URL.GET_TERRATORY_STATE_CITY + "/" + zipCode,null);
  }
  
}
